import {
  useState,
  forwardRef,
  useCallback,
  InputHTMLAttributes,
  MouseEvent,
  TextareaHTMLAttributes,
  ReactNode,
} from 'react';
import { Invisible, Visible } from './Icons';

export type ITextFieldProps = InputHTMLAttributes<HTMLInputElement> & ICommonProps;

const TextField = forwardRef<HTMLInputElement, ITextFieldProps>((props, ref) => {
  const {
    label, id, error, helperText, errorText, onChange, type = 'search', className = '', inputClasses = '', ...rest
  } = props;

  const [showPass, setShowPass] = useState(false);

  const togglePass = useCallback((e:MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPass(current => !current);
  }, []);

  return (
    <FieldBase
      id={id}
      label={label}
      helperText={helperText}
      error={error}
      errorText={errorText}
      className={className}
    >

      <div className="relative">
        <input
          ref={ref}
          id={id ?? label}
          type={showPass ? 'text' : type}
          onChange={onChange}
          className={`peer ${getCommonClasses(error)} ${inputClasses}`}
          {...rest}
        />

        {
          type === 'password' && (
            <button
              type="button"
              tabIndex={-1}
              onClick={togglePass}
              className="text-neutral-action absolute right-4 top-3 z-40
              peer-placeholder-shown:!invisible"
            >
              {
                showPass
                  ? <Visible width={32} height={32} />
                  : <Invisible width={32} height={32} />
              }
            </button>
          )
        }
      </div>

    </FieldBase>
  );
});

export type ITextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & ICommonProps;

export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>((props, ref) => {
  const { label, id, error, helperText, errorText, onChange, className = '', inputClasses, ...rest } = props;

  return (
    <FieldBase
      id={id}
      label={label}
      helperText={helperText}
      error={error}
      errorText={errorText}
      className={className}
    >
      <textarea
        id={id}
        ref={ref}
        rows={3}
        onChange={onChange}
        className={`resize-none ${getCommonClasses(error)} ${inputClasses}`}
        {...rest}
      />
    </FieldBase>
  );
});

interface IFieldBase extends ICommonProps {
  children: ReactNode;
}

const FieldBase = ({ id, label, helperText, error, errorText, className = '', children }: IFieldBase) => (
  <div className={`relative flex flex-col ${className}`}>
    <label
      htmlFor={id ?? label}
      className={
        `block pl-1 pb-1 text-callout font-medium
        ${error ? 'text-danger-neutral-text-softer' : 'text-neutral-text'}`
      }
    >
      {label}
    </label>

    {children}

    {
      Boolean(helperText || errorText) &&
      <p
        className={`pt-2 pl-2 first-letter:uppercase
          ${error ? 'text-danger-neutral-text-softer' : helperText ? 'text-info-neutral-text-softer' : ''}
        `}
      >
        {error ? errorText : helperText}
      </p>
    }
  </div>
);

interface ICommonProps {
  label: string;
  id?: string;
  className?: string;
  inputClasses?: string;
  helperText?: string;
  errorText?: string;
  error?: boolean;
}

const getCommonClasses = (error = false) =>
  `outline-none w-full
  p-4 pl-5 rounded-xs bg-neutral-canvas3
  text-neutral-canvas3-text text-body font-medium
  placeholder:text-neutral-placeholder
  focus:outline-3 focus:outline-secondary focus:neutral-canvas3-active-text
  ${error && 'outline-3 !outline-danger'}`;

export default TextField;
