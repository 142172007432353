const PrivacyPolicy = () => (
  <div className="w-full h-full max-w-5xl mx-auto">
    {/* eslint-disable max-len */}
    <ol className="flex flex-col gap-4 list-decimal p-10">
      <li>
        <strong>Introduction:</strong>
        <p>Welcome to SATPrac! We respect your privacy and are committed to protecting your personal data. This privacy policy will provide you with information about how we handle your personal data and about your privacy rights.</p>
      </li>
      <li>
        <strong>Personal Data We Collect:</strong>
        <ol className="list-decimal pl-4">
          <li>Account information: name, email address, password, phone.</li>
          <li>Performance data: your test scores, answers, and progress.</li>
          <li>Payment data: if you make a purchase, we collect data necessary to process your payment.</li>
          <li>Technical data: IP address, login data, and device type.</li>
        </ol>
      </li>
      <li>
        <strong>How We Use Your Personal Data:</strong>
        <ol className="list-decimal pl-4">
          <li>Provide, maintain and improve our services.</li>
          <li>Process transactions and send related information.</li>
          <li>Respond to your comments, questions, and requests.</li>
          <li>Monitor and analyze trends and usage.</li>
        </ol>
      </li>
      <li>
        <strong>Disclosure of Data:</strong>
        <p>We do not share, sell, or disclose your personal data to third parties, except as described in this policy.</p>
      </li>
      <li>
        <strong>Data Security:</strong>
        <p>We use various security measures to protect your data, including encryption and secure servers.</p>
      </li>
      <li>
        <strong>Cookies:</strong>
        <p>We use cookies and similar tracking technologies to track activity on our website and hold certain information.</p>
      </li>
      <li>
        <strong>Your Privacy Rights:</strong>
        <ol className="list-decimal pl-4">
          <li>Access, update or delete the information we have on you.</li>
          <li>Object to our processing of your personal data.</li>
          <li>Request portability of your personal data.</li>
        </ol>
      </li>
      <li>
        <strong>Third-Party Websites:</strong>
        <p>Our website may contain links to third-party websites. We are not responsible for the content or practices of these sites.</p>
      </li>
      <li>
        <strong>Changes to This Privacy Policy:</strong>
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
      </li>
    </ol>
  </div>
);

export default PrivacyPolicy;
