import { ButtonHTMLAttributes, forwardRef } from 'react';
import { ArrowRight } from './Icons.tsx';
import { Spinner } from './Loader.tsx';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
  withArrow?: boolean;
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const { color = 'primary', type = 'button', className = '', withArrow, loading, children, disabled, ...rest } = props;
  // eslint-disable-next-line max-len
  const classNames = `inline-flex justify-center items-center gap-2 py-2.5 px-4 rounded text-callout font-semibold ${VARIANTS[color]} ${className}`;

  return (
    <button type={type} ref={ref} className={classNames} {...rest} disabled={loading || disabled}>
      {loading && <Spinner className="w-6"/>}
      {children}
      {withArrow && <ArrowRight width={16} height={16}/>}
    </button>
  );
});

const VARIANTS = {
  primary: 'bg-primary text-primary-text hover:bg-primary-hover disabled:bg-primary-disabled',
  secondary: 'bg-secondary text-secondary-text hover:bg-secondary-hover disabled:bg-secondary-disabled',
  success: 'bg-success text-success-text hover:bg-success-hover disabled:bg-success-disabled',
  danger:
  'bg-danger text-danger-text hover:bg-danger-hover disabled:bg-danger-disabled disabled:text-danger-disabled-text',
  warning: 'bg-warning text-warning-text hover:bg-warning-hover disabled:bg-warning-disabled',
  info: 'bg-info text-info-text hover:bg-info-hover disabled:bg-info-disabled',
};



export default Button;
