const Terms = () => (
  <div className="w-full h-full max-w-5xl mx-auto">
    {/* eslint-disable max-len */}
    <ol className="flex flex-col gap-4 list-decimal p-10">
      <li>
        <strong>Introduction:</strong>
        <p>By accessing and using SATPrac, you agree to comply with the terms and conditions outlined here. Please read them carefully.</p>
      </li>
      <li>
        <strong>User Agreement:</strong>
        <p>You must provide accurate information when creating an account.</p>
      </li>
      <li>
        <strong>Use of Content:</strong>
        <ol className="list-decimal pl-4">
          <li>All content is owned by SATPrac and is protected by copyright.</li>
          <li>You may not distribute, reproduce, or sell any content from SATPrac without our written consent.</li>
        </ol>
      </li>
      <li>
        <strong>User Obligations:</strong>
        <ol className="list-decimal pl-4">
          <li>You must not misuse our platform by introducing viruses or harmful data.</li>
          <li>You agree not to attempt unauthorized access to our server or database.</li>
        </ol>
      </li>
      <li>
        <strong>Payments:</strong>
        <p>Some services may require payment. All payments are final and non-refundable.</p>
      </li>
      <li>
        <strong>Limitation of Liability:</strong>
        <p>SATPrac is provided “as is”. We make no warranties, and we shall not be liable for any damages or loss arising from your use or inability to use our service.</p>
      </li>
      <li>
        <strong>Termination:</strong>
        <p>We reserve the right to suspend or terminate your account if you violate these terms.</p>
      </li>
      <li>
        <strong>Changes to Terms:</strong>
        <p>We may modify these terms at any time. Changes will be posted on SATPrac, and by continuing to use the site, you agree to be bound by such modifications.</p>
      </li>
    </ol>
  </div>
);

export default Terms;
