import { ButtonHTMLAttributes, FunctionComponent, SVGProps, useState } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import UserInfo from '@/components/UserInfo.tsx';
import Dialog from '@/components/Dialog.tsx';
import { TypoBody } from './Typography';
import { Contact, Dashboard, Gift, Highlighter, SignOut, Video } from './Icons';
import useLogout from '@/hooks/useLogout';

interface BaseProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title?: string;
}

interface MenuLinkProps extends BaseProps, NavLinkProps {}
interface MenuButtonProps extends BaseProps, ButtonHTMLAttributes<HTMLButtonElement> {}

const Sidebar = () => (
  <section className="fixed left-0 top-0 h-full pl-16">
    <UserInfo className="px-4 pt-7 pb-9" />
    <nav className="flex flex-col gap-1 items-start">
      <MenuLink Icon={Dashboard} title="Dashboard" to="/dashboard" />
      <MenuLink Icon={Video} title="Video Tutorials" to="/video-tutorials" />
      <MenuLink Icon={Contact} title="Contact Idiot Tutor" to="/contact" />
      <MenuLink Icon={Gift} title="SATPrac Packages" to="/packages" />
      <MenuLink Icon={Highlighter} title="Profile" to="/profile" />
      <LogoutButton />
    </nav>
  </section>
);

const LogoutButton = () => {
  const { logout, loading } = useLogout();
  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuButton Icon={SignOut} title="Sign Out" onClick={() => setOpen(true)} />
      <Dialog
        title="Are you sure?"
        description="The world is not going to end but if you stay your knowledge will be enriched!"
        onAction={logout}
        open={open}
        onClose={() => setOpen(false)}
        cancelText="Stay"
        actionText="Log me out!"
        actionLoading={loading}
      />
    </>
  );
};

const MenuLink = ({ Icon, to, title, ...rest }: MenuLinkProps) => (
  <NavLink
    to={to}
    className={({ isActive }) => getClassNames(isActive ? 'bg-shade-5 active' : '')}
    {...rest}
  >
    <MenuBase Icon={Icon} title={title} />
  </NavLink>
);

const MenuButton = ({ Icon, title, onClick, ...rest }: MenuButtonProps) => (
  <button
    onClick={onClick}
    className={getClassNames()}
    {...rest}
  >
    <MenuBase Icon={Icon} title={title} />
  </button>
);

const MenuBase = ({ Icon, title = '' }: BaseProps) => (
  <>
    <Icon width="40" height="40" className="p-1 text-neutral-icon group-[.active]:text-primary" />
    <TypoBody className="text-neutral2-text font-medium">{title}</TypoBody>
  </>
);

const getClassNames = (className = '') =>
  `flex items-center gap-4 rounded-sm hover:bg-shade-5 pl-4 pr-5 py-1 group ${className}`;

export default Sidebar;
