import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import UPDATE_PROFILE_MUTATION from '@/graphql/mutations/update-profile.graphql';
import Button from '@/components/Button';
import TextField from '@/components/TextField';
import notify from '@/utils/notifications.tsx';

interface ProfileFormProps {
  firstName?: string;
  lastName?: string;
  phone?: string;
  accommodationFactor?: number;
}

const UpdateProfileForm = ({
  firstName = '',
  lastName = '',
  phone = '',
  accommodationFactor = 1
}: ProfileFormProps) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: { firstName, lastName, phone, accommodationFactor },
    resolver: yupResolver(ProfileSchema),
  });

  const [updateProfile, { loading, data }] = useMutation(
    UPDATE_PROFILE_MUTATION, {
      onCompleted: () => {
        notify('Success!', 'The profile was updated successfully!', { type: 'success' });
      },
      onError: (apolloError) => console.log(apolloError),
    }
  );

  const onSubmit = useCallback(
    async (input: ProfileFormProps) => {
      clearErrors();

      const clientInfoInput = {
        ...input,
        accommodationFactor: Number(input?.accommodationFactor?.toFixed(1))
      };

      await updateProfile({ variables: { clientInfoInput } });
    },
    [updateProfile, clearErrors]
  );

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4"
    >
      <TextField
        {...register('accommodationFactor')}
        type="number"
        step="0.1"
        placeholder="Accommodation Factor"
        label="Accommodation Factor"
        error={Boolean(errors?.accommodationFactor)}
        errorText={errors?.accommodationFactor?.message}
      />
      <TextField
        {...register('firstName')}
        type="text"
        placeholder="John"
        label="First Name (Optional)"
        error={Boolean(errors?.firstName)}
        errorText={errors?.firstName?.message}
      />
      <TextField
        {...register('lastName')}
        type="text"
        placeholder="Doe"
        label="Last Name (Optional)"
        error={Boolean(errors?.lastName)}
        errorText={errors?.lastName?.message}
      />
      <TextField
        {...register('phone')}
        type="text"
        placeholder="Example: +12124567890"
        label="Phone (Optional)"
        error={Boolean(errors?.phone)}
        errorText={errors?.phone?.message}
      />
      <div className="flex items-center justify-end gap-4">
        <Button type="submit" disabled={loading}>
          {loading ? 'Updating' : data?.user ? 'Updated' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

const ProfileSchema = yup.object().shape({
  accommodationFactor: yup
    .number()
    .min(1)
    .max(3)
    .transform((value) => Number.isNaN(value) ? null : value)
    .nullable()
    .required('Required field'),
  firstName: yup.string().transform((_, v) => v || undefined),
  lastName: yup.string().transform((_, v) => v || undefined),
  phone: yup.string().test({
    name: 'valid-phone',
    message: 'Phone number is not valid',
    exclusive: true,
    test(value) {
      if (!value) return true;

      return isValidPhoneNumber(value);
    },
  }).transform((_, v) => v || undefined),
});

export default UpdateProfileForm;
