import { ReactNode } from 'react';
import { TypoHeading2 } from '@/components/Typography';
import Button from '@/components/Button';
import Loader from '@/components/Loader.tsx';
import useUser from '@/hooks/useUser.ts';
import UpdateProfileForm from './UpdateProfileForm.tsx';
import UpdatePasswordForm from './UpdatePasswordForm.tsx';
import useEmailVerification from '@/hooks/useEmailVerification.ts';

const Profile = () => {
  const { user, loading } = useUser();
  const { isEmailVerified, ...rest } = user;

  if (loading) return <Loader />;

  return (
    <>
      {!isEmailVerified && <ResendEmailVerification />}

      <Container title="Update Profile">
        <UpdateProfileForm {...rest} />
      </Container>

      <Container title="Update Password">
        <UpdatePasswordForm />
      </Container>
    </>
  );
};

const ResendEmailVerification = () => {
  const { resendEmailVerification, loading } = useEmailVerification();

  return (
    <Container title="Email Verification">
      <Button onClick={() => resendEmailVerification()} loading={loading}>Resend Email Verification</Button>
    </Container>
  );
};

const Container = ({ title, children }: { title: string, children: ReactNode }) => (
  <div className="flex flex-col py-6 gap-4">
    <TypoHeading2 className="text-neutral-text-strong font-medium px-10">
      {title}
    </TypoHeading2>
    <div className="w-full bg-neutral2 rounded px-10 py-8">
      {children}
    </div>
  </div>
);

export default Profile;
