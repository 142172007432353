import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import Button from './Button';
import { TypoBody } from './Typography';
import MissingTests from './MissingTests';

export interface TestRecordProps {
  examId: number;
  clientExamId: number;
  title: string;
  isCompleted: boolean;
  datetime: string;
  totalScore: number;
}

export interface TestHistoryProps {
  tests: TestRecordProps[];
}

const TestHistory = ({ tests }:TestHistoryProps) => {
  if (!tests.length) return <MissingTests text="No test history yet." />;

  return (
    <div className="flex w-full max-w-xl flex-col gap-1 py-4">
      {
        tests.map(test => (
          <TestRecord
            key={test.clientExamId}
            examId={test.examId}
            clientExamId={test.clientExamId}
            title={test.title}
            datetime={test.datetime}
            isCompleted={test.isCompleted}
            totalScore={test.totalScore}
          />
        ))
      }
    </div>
  );
};

const TestRecord = ({ examId, clientExamId, isCompleted, title, datetime, totalScore }: TestRecordProps) => (
  <div className="flex gap-2 justify-between items-center p-2 pl-6 rounded-lg bg-neutral">
    <TypoBody className="flex flex-col justify-center gap-0.5 text-neutral-text-softer">
      <span className="text-neutral-text">{title}</span>
      <span>{DateTime.fromISO(datetime).toLocaleString({ dateStyle: 'medium', timeStyle: 'short' })}</span>
    </TypoBody>
    {
      isCompleted
        ? (
          <div className="flex items-center gap-2 shrink-0">
            <span>Score: {totalScore}</span>
            <Link to={`/review/${examId}/${clientExamId}`}>
              <Button withArrow color="info">Review</Button>
            </Link>
          </div>
        )
        : (
          <Link to={`/tests/${examId}`}>
            <Button withArrow>Resume</Button>
          </Link>
        )
    }
  </div>
);


export default TestHistory;
