import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BlockMath } from 'react-katex';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@/components/TextField';
import { IQuestion } from '@/types/question.types';
import Button from '@/components/Button';
import { TypoCallout } from '@/components/Typography';
import { useAnswerMutation } from '@/hooks/exam/useAnswerMutation.ts';

interface AnswerTextFieldProps {
  currentQuestion: IQuestion<string>;
  incorrect?: boolean;
  readOnly?: boolean;
}

const AnswerTextField = ({ currentQuestion, incorrect, readOnly }: AnswerTextFieldProps) => {
  const { examQuestionId, answers } = currentQuestion;
  const { examComponentId } = useParams();
  const { answerCallback, loading } = useAnswerMutation(Number(examComponentId));

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: { answerInput: '' },
    resolver: yupResolver(AnswerInputSchema)
  });

  const answerInputValue = watch('answerInput');

  const submitCallback = useCallback(async ({ answerInput = '' }) => {
    if (readOnly) return;

    clearErrors();
    await answerCallback({ examQuestionId, answerInput: answerInput.replace(/\s+/g, '') });
  }, [answerCallback, clearErrors, examQuestionId, readOnly]);

  useEffect(() => {
    reset({ answerInput: currentQuestion?.answerInput ?? '' });
  }, [reset, currentQuestion]);

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(submitCallback)}
        className="flex items-end gap-4"
      >
        <TextField
          {...register('answerInput')}
          type="text"
          label="Your Answer"
          placeholder="Example: 1/2"
          error={Boolean(errors?.answerInput) || incorrect}
          readOnly={readOnly}
          disabled={readOnly}
        />
        <div className="max-h-16 text-neutral-text">
          <BlockMath renderError={ErrorMessage}>
            {toFraction(answerInputValue)}
          </BlockMath>
        </div>
        {
          !readOnly && (
            <Button className="mb-2" type="submit" disabled={loading}>
              Submit
            </Button>
          )
        }
      </form>
      {
        readOnly && incorrect && (
          <div className="flex flex-col items-start text-success-neutral-text-softer">
            <TypoCallout className="font-medium">Correct Answer is:</TypoCallout>
            <BlockMath>
              {toFraction(String(answers[0]?.answer))}
            </BlockMath>
          </div>
        )
      }
    </>
  );
};

const AnswerInputSchema = yup.object().shape({
  answerInput: yup.string().required(),
});

const ErrorMessage = () => <p className="h-14 text-danger flex items-center">Wrong Expression</p>;

const toFraction = (value: string) => {
  if (!/\/|\\/.test(value)) return value;

  const [numerator, denominator] = value.split(/\/|\\/);

  return `\\frac{${numerator}}{{${denominator}}}`;
};

export default AnswerTextField;
