import { ApolloClient, createHttpLink, InMemoryCache, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import notify from '@/utils/notifications.tsx';

const cache = new InMemoryCache({
  typePolicies: {
    ClientInformation: {
      keyFields: ['clientId'],
    },
    ClientExamComponent: {
      keyFields: ['clientExamComponentId'],
    },
    ClientExamQuestion: {
      keyFields: ['clientExamQuestionId'],
    },
    ClientExamQuestionAnnotation: {
      keyFields: ['clientExamQuestionAnnotationId'],
    },
    Query: {
      fields: {
        clientExams: {
          keyArgs: ['isCompleted'],
          merge: false,
        },
        clientExam: {
          keyArgs: ['clientExamId'],
        },
      },
    },
    Subscription: {
      fields: {
        timer: {
          keyArgs: ['examId'],
        }
      }
    }
  },
});

const httpLink = createHttpLink({
  uri: `https://${import.meta.env.VITE_API_ORIGIN}/api/graphql`,
});

const wsLink = new GraphQLWsLink(createClient({
  url: `wss://${import.meta.env.VITE_API_ORIGIN}/api/graphql`,
  on: {
    error: (errors) => {
      console.error(`ERROR: ${JSON.stringify(errors)}`);
    },
    connected: () => {
      console.log(`socket:CONNECTED ${new Date()}`);
    },
    closed: () => {
      console.log(`socket:CLOSED ${new Date()}`);
    },
    connecting: () => {
      console.log(`socket:CONNECTING ${new Date()}`);
    },
  }
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      const errors = extensions?.fields ?? {};
      const error = Object.values(errors).join(', ');

      if (extensions?.code === RESPONSE_CODES.FORBIDDEN) {
        localStorage.clear();
        window.location.reload();
        return;
      }

      if (message) {
        if (error) {
          notify(message, error, { type: 'error' });
        }
        else {
          notify('Something went wrong!', message, { type: 'error' });
        }
      }
      else {
        notify('Something went wrong!', error, { type: 'error' });
      }
    });
  }

  if (networkError) {
    notify('Network error!', networkError?.message ?? '', { type: 'error' });
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('authToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});

const apolloLink = from([errorLink, authLink, httpLink]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  apolloLink,
);
const client = new ApolloClient({
  link: splitLink,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
});

const RESPONSE_CODES = {
  FORBIDDEN: 403,
};

export default client;
