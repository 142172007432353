import { TypoCallout } from '@/components/Typography.tsx';
import useEmailVerification from '@/hooks/useEmailVerification.ts';
import useUser from '@/hooks/useUser.ts';

const EmailVerificationBanner = () => {
  const { user, loading } = useUser();
  if (loading || user.isEmailVerified) return null;

  return (
    <div
      className={`w-full 
          px-6 py-4 mt-6 rounded-lg bg-neutral2 border border-dashed border-shade-20 sticky top-0 z-50
          shadow-md
        `}
      role="banner"
    >
      <TypoCallout className="text-neutral2-text-strong">
        Please verify your email to enable certain features (e.g., purchasing packages).
      </TypoCallout>
      <ResendEmailButton />
    </div>
  );
};

const ResendEmailButton = () => {
  const { resendEmailVerification, loading } = useEmailVerification();

  return (
    <button
      disabled={loading}
      onClick={() => resendEmailVerification()}
      className="block mx-auto text-primary font-medium hover:text-primary-hover"
    >
      <TypoCallout>resend email</TypoCallout>
    </button>
  );
};

export default EmailVerificationBanner;
