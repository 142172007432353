import { DataProp } from 'editorjs-blocks-react-renderer';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';
import { TypoBody } from '@/components/Typography.tsx';

const AnswerDirections = ({ directions, isSelectType }: { directions: DataProp; isSelectType?: boolean; }) => {
  if (directions?.blocks?.length) {
    return <BlocksRenderer
      data={directions}
      config={{
        paragraph: {
          className: 'font-primary text-body mt-2 text-body font-medium text-neutral-text',
          actionsClassNames: {
            alignment: 'text-{alignment}',
          }
        },
      }}
    />;
  }

  return (
    <TypoBody className="font-medium mt-2 text-neutral-text">
      {/*eslint-disable max-len*/}
      {
        isSelectType
          ? 'Select your answer choice below. Optionally, mark it for review or use strike-through to eliminate options.'
          : ''
      }
    </TypoBody>
  );
};

export default AnswerDirections;
