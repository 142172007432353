import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import CLIENT_INFO from '@/graphql/queries/user-info.graphql';
import useAuth from '@/hooks/useAuth.ts';

const useUser = () => {
  const auth = useAuth();
  const { loading, error, data } = useQuery(CLIENT_INFO, {
    skip: !auth.clientId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const user = useMemo(() => data?.user ?? {}, [data?.user]);

  const profileName = useMemo(() => {
    let fullName = '';

    if (user.firstName) fullName = fullName.concat(user.firstName, ' ');
    if (user.lastName) fullName = fullName.concat(user.lastName);

    return fullName || user.email;
  }, [user]);

  return {
    loading,
    error,
    user: {
      ...user,
      accommodationFactor: user?.accommodationFactor?.toFixed(1),
      profileName,
    },
  };
};

export default useUser;
