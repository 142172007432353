import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TypoBody, TypoHeading2 } from '@/components/Typography.tsx';
import Button from '@/components/Button.tsx';
import TextField, { Textarea } from '@/components/TextField.tsx';
import { JobApplication } from '@/components/Icons.tsx';
import CONTACT_MUTATION from '@/graphql/mutations/contact.graphql';

const Contact = () => {
  const [completed, setCompleted] = useState(false);
  const onCompleted = useCallback(() => {
    console.log('Message sent');
    setCompleted(true);
  }, []);

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        Contact Idiot Tutor
      </TypoHeading2>
      <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
        {!completed && <ContactForm onCompleted={onCompleted} />}
        {completed && <MessageSent />}
      </div>
    </>
  );
};

const ContactForm = ({ onCompleted }: { onCompleted (): void }) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: { subject: '', message: '' },
    resolver: yupResolver(ContactSchema),
  });

  const [send, { loading }] = useMutation(CONTACT_MUTATION, {
    onCompleted,
    onError: console.error,
  });

  const onSubmit = useCallback(async ({ subject = '', message = '' }) => {
    const input = { subject, message };
    clearErrors();
    await send({ variables: input });
    reset();
    sessionStorage.removeItem('contactForm');
  }, [send, clearErrors, reset]);

  useEffect(() => {
    const savedData = sessionStorage.getItem('contactForm');
    if (savedData) {
      reset(JSON.parse(savedData));
    }
  }, [reset]);

  useEffect(() => {
    const subscription = watch((value) => {
      sessionStorage.setItem('contactForm', JSON.stringify(value));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <TypoBody className="my-8">
        Do you have a question for the Idiot Tutor ? Is there a question on the site you can't figure out?
        If there is anything I can do for you at all, please let me know! Send me a message below and I will
        get back to you as quickly as I possibly can!
      </TypoBody>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <TextField
          {...register('subject')}
          type="text"
          label=""
          id="contact-subject"
          placeholder="Subject"
          error={Boolean(errors?.subject)}
          errorText={errors?.subject?.message}
          required
        />
        <Textarea
          {...register('message')}
          label=""
          id="contact-message"
          placeholder="Message"
          rows={6}
          error={Boolean(errors?.message)}
          errorText={errors?.message?.message}
          required
        />
        <div className="flex items-center justify-end gap-4">
          <Button color="success" type="submit" disabled={loading}>
            {loading ? 'Sending Message' : 'Send Message'}
          </Button>
        </div>
      </form>
    </>
  );
};

const MessageSent = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center gap-6 pt-10 pb-6 px-10">
      <JobApplication width={200} height={200} />
      <TypoBody className="mt-2 mb-6">
        Thank you for reaching out! Idiot Tutor's minions are delivering the message to him as we speak and
        you will hear back from him shortly!
      </TypoBody>
      <Button color="success" onClick={() => navigate(-1)}>Done, go back</Button>
    </div>
  );
};

const ContactSchema = yup.object().shape({
  subject: yup.string().min(4).required(),
  message: yup.string().min(20).required(),
});

export default Contact;
