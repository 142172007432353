import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import TestAnswer from '@/pages/TestPage/TestAnswer';
import { AnswerQuestionInputProps, ClientCrossingsInputProps, IQuestion } from '@/types/question.types.ts';
import QUESTION_CROSSING_MUTATION from '@/graphql/mutations/question-crossing.graphql';
import QUESTION_CROSSING_DELETE_MUTATION from '@/graphql/mutations/question-crossing-delete.graphql';
import CLIENT_EXAM_COMPONENT_QUERY from '@/graphql/queries/client-exam-component.graphql';
import { useAnswerMutation } from '@/hooks/exam/useAnswerMutation.ts';

interface AnswerSelectProps {
  currentQuestion: IQuestion;
  showCrossButton: boolean;
}

const AnswerSelect = ({ currentQuestion, showCrossButton }: AnswerSelectProps) => {
  const {
    examAnswerId: selectedAnswerId,
    examQuestionId,
    crossings,
    answers
  } = currentQuestion ?? {};

  const { examComponentId } = useParams();
  const { answerCallback, loading } = useAnswerMutation(Number(examComponentId));
  const { crossCallback, loading: crossLoading } = useCrossMutation(Number(examComponentId));
  const { removeCrossCallback, loading: removeCrossLoading } = useRemoveCrossMutation(Number(examComponentId));

  const onAnswerQuestion = useCallback(async (answerId: AnswerQuestionInputProps['examAnswerId']) => {
    const examAnswerId = selectedAnswerId === answerId ? undefined : answerId;
    await answerCallback({ examQuestionId, examAnswerId });
  }, [answerCallback, examQuestionId, selectedAnswerId]);

  const onCrossToggle = useCallback(async (id: number, crossed?: boolean) => {
    if (crossed) await removeCrossCallback(id);
    else {
      if (!selectedAnswerId) await answerCallback({ examQuestionId }, false);
      await crossCallback({ examQuestionId, examAnswerIds: [id] });
    }
  }, [answerCallback, crossCallback, examQuestionId, removeCrossCallback, selectedAnswerId]);

  return (
    <>
      {
        answers.map((item, index) => {
          const crossing = crossings?.find((c) => c.examAnswerId === item.examAnswerId);
          const selected = item.examAnswerId === selectedAnswerId;
          const type = selected ? 'correct' : crossing && 'crossed';
          const crossId = crossing ? crossing.clientExamQuestionCrossingId : item.examAnswerId;

          return (
            <TestAnswer
              key={item.examAnswerId}
              index={index}
              type={type}
              answer={item.answer}
              showCrossButton={showCrossButton}
              loading={loading}
              crossLoading={crossLoading || removeCrossLoading}
              onToggle={() => onAnswerQuestion(item.examAnswerId)}
              onCrossToggle={() => onCrossToggle(crossId, Boolean(crossing))}
            />
          );
        })}
    </>
  );
};


const useCrossMutation = (examComponentId: number) => {
  const [crossAnswer, { loading }] = useMutation(QUESTION_CROSSING_MUTATION, {
    refetchQueries: [{ query: CLIENT_EXAM_COMPONENT_QUERY, variables: { examComponentId } }],
  });

  const crossCallback = useCallback(async (input: ClientCrossingsInputProps) => {
    await crossAnswer({ variables: { input } });
  }, [crossAnswer]);

  return { crossCallback, loading };
};

const useRemoveCrossMutation = (examComponentId: number) => {
  const [removeCross, { loading }] = useMutation(QUESTION_CROSSING_DELETE_MUTATION, {
    refetchQueries: [{ query: CLIENT_EXAM_COMPONENT_QUERY, variables: { examComponentId } }],
  });

  const removeCrossCallback = useCallback(async (id: number) => {
    await removeCross({
      variables: { clientExamQuestionCrossingIds: [id], },
    });
  }, [removeCross]);

  return { removeCrossCallback, loading };
};

export default AnswerSelect;
