import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Button from '@/components/Button.tsx';
import { TypoBody, TypoHeading, TypoTitle2 } from '@/components/Typography.tsx';
import TestList, { TestListProps } from '@/components/TestList.tsx';
import PURCHASE_OPTIONS_QUERY from '@/graphql/queries/purchase-options.graphql';
import { toJSON } from '@/utils/helpers.tsx';

const Pricing = () => {
  const { products, loading } = useProductsData();

  return (
    <section
      id="pricing"
      className="flex flex-col items-center justify-center h-screen bg-neutral py-24 px-4"
    >
      <div className="flex items-center justify-between gap-24 w-full max-w-5xl">
        <div className="w-full shrink-0 max-w-sm">
          <TypoTitle2 className="text-neutral-text mb-8">Choose Your SATPrac Pack</TypoTitle2>
          <Link to="/sign-up">
            <Button type="button" withArrow>Get Started Now</Button>
          </Link>
        </div>

        <div className="flex-1 bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
          <TypoHeading className="text-neutral-text font-medium py-2">
            Exclusive Tests and SATPrac Packs Just For You
          </TypoHeading>
          <TypoBody className="mt-2 mb-8">
            Grab our specially curated bundles and get a head start on your test prep journey.
          </TypoBody>
          <div className="max-h-[55vh] px-1 overflow-y-auto">
            <TestList loading={loading} list={products} withPrice hideButton />
          </div>
        </div>
      </div>
    </section>
  );
};

const useProductsData = () => {
  const { data, loading } = useQuery(PURCHASE_OPTIONS_QUERY);

  const products: TestListProps['list'] = useMemo(() =>
    (data?.purchaseOptions ?? [])
      .filter((product: ProductProps) => product?.price?.productPriceId)
      .map((product: ProductProps) => {
        const examsByType = product.exams.reduce((list: any[], exam: ProductProps['exams'][0]) => {
          const current = list.find(i => i.type === exam.examType.name);

          if (current) current.exams.push(exam);
          else list.push({ type: exam.examType.name, exams: [exam] });

          return list;
        }, []);

        const types = examsByType.map(({ exams, type }) => {
          const typeText = type === 'SET' ? 'SATPrac Pack' : `${type} Test`;

          return `${exams.length} ${typeText}${exams.length > 1 ? 's' : ''}`;
        });

        return {
          id: product.productId,
          title: product.name,
          description: toJSON(product.description),
          subtitle: types.join(' | '),
          price: product?.price?.price ?? 0,
          priceId: product.price.productPriceId,
        };
      }), [data?.purchaseOptions]);

  return { products, loading };
};

interface ProductProps {
  productId: number;
  name: string;
  description: string;
  price: { productPriceId: number; price: number; }
  exams: {
    examId: number;
    name: string;
    examType: { name: string }
  }[];
}

export default Pricing;
