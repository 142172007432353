import { ReactNode } from 'react';

interface ContainerProps {
  className?: string;
  children: ReactNode;
}

const NavContainer = ({ className = '', children }: ContainerProps) =>
  <section className={`bg-neutral2 h-18 px-16 flex-shrink-0 flex items-center justify-between ${className}`}>
    {children}
  </section>;

export default NavContainer;
