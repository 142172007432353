import { memo, useCallback, useEffect, useRef, useState } from 'react';
import BlocksRenderer, { RendererProps } from '@/components/BlocksRenderer.tsx';
import AnnotationPopup from './AnnotationPopup.tsx';
import type { Annotation, HighlightColorId } from '@/types/question.types.ts';
import { COLOR_OPTIONS } from '@/components/Annotations/COLOR_OPTIONS.tsx';

function HighlightableBlocks({
  data,
  initialAnnotations = [],
  saveAnnotation,
  deleteAnnotation,
  config,
}: HighlightableBlocksProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [annotations, setAnnotations] = useState<Annotation[]>(initialAnnotations);
  const [selectedAnnotation, setSelectedAnnotation] = useState<Annotation | null>(null);
  const [popupAnchor, setPopupAnchor] = useState<HTMLElement | null>(null);

  const updateColor = useCallback((color: HighlightColorId) => {
    if (selectedAnnotation) {
      const updated = { ...selectedAnnotation, highlightColor: color };
      setSelectedAnnotation(updated);
      setAnnotations(prev => prev.map(a => a === selectedAnnotation ? updated : a));
      sessionStorage.setItem('annotation-color', color.toString());
      saveAnnotation(updated).catch(console.error);
    }
  }, [saveAnnotation, selectedAnnotation]);

  const deleteSelectedAnnotation = useCallback(() => {
    if (selectedAnnotation?.clientExamQuestionAnnotationId) {
      setSelectedAnnotation(null);
      setAnnotations(prev => prev.filter(a => a !== selectedAnnotation));
      deleteAnnotation(selectedAnnotation.clientExamQuestionAnnotationId).catch(console.error);
    }
  }, [deleteAnnotation, selectedAnnotation]);

  // Apply annotations to the DOM
  useEffect(() => {
    if (!containerRef.current) return;

    // Remove existing highlights
    containerRef.current.querySelectorAll('.annotation-highlight').forEach(el => {
      el.replaceWith(...el.childNodes);
    });

    // Apply new annotations
    annotations.forEach(annotation =>
      containerRef.current &&
      applyAnnotation(containerRef.current, annotation, () => setSelectedAnnotation(annotation))
    );
  }, [annotations, data]);

  useEffect(() => {
    if (selectedAnnotation && containerRef.current) {
      const id = selectedAnnotation.clientExamQuestionAnnotationId;
      const marks = containerRef.current.querySelectorAll(`[data-annotation-id="${id}"]`);
      console.assert(marks.length > 0, `Could not find annotation with id ${id}`);
      const last = marks[marks.length - 1];
      if (last instanceof HTMLElement) setPopupAnchor(last);
    }
  }, [selectedAnnotation]);

  // Listen for mouseup events to capture new selections
  useEffect(() => {
    const createAnnotationFromSelection = () => {
      const selection = window.getSelection();
      if (!selection || selection.isCollapsed || !containerRef.current) return;

      const range = selection.getRangeAt(0);

      // Ensure the selection is within our container
      if (!containerRef.current.contains(range.commonAncestorContainer)) return;

      // Todo: if the range is within another annotation with the same color we do nothing
      // Todo: if range overlaps or wraps with another annotation with the same color we merge the two
      // It may not be necessary to implement these todos, let's see how demo goes and update as needed

      // I see two ways to implement the todos above:
      // 1. We can check for overlap using offset and selection length
      // 2. Or we can use nodes inside the range and check whether they are child or a parent of a mark element
      // The offset overlap might be less logic as ranges have 3 sets of elements to check
      // We might need to run this logic as part of any annotation update
      // e.g. changing color can merge an annotations with the same color

      const newAnnotation: Annotation = {
        clientExamQuestionAnnotationId: 0, // temp id
        highlightColor: getCurrentHighlightColor(),
        offset: getTextOffset(containerRef.current, range),
        selection: normalizeSelection(selection),
      };

      setSelectedAnnotation(newAnnotation);
      setAnnotations(prev => [...prev, newAnnotation]);

      saveAnnotation(newAnnotation).then((created) => {
        setAnnotations(prev => prev.map(a => a === newAnnotation ? created : a));
        setSelectedAnnotation(created);
      });

      // Clear selection
      selection.removeAllRanges();
    };

    document.addEventListener('mouseup', createAnnotationFromSelection);
    return () => document.removeEventListener('mouseup', createAnnotationFromSelection);
  }, [saveAnnotation]);

  return (
    <div ref={containerRef} className={`relative ${COLOR_OPTIONS[getCurrentHighlightColor()].selection}`}>
      <MemoizedBlocksRenderer data={data} config={config} />
      <AnnotationPopup
        anchor={popupAnchor}
        open={Boolean(selectedAnnotation)}
        onClose={() => setSelectedAnnotation(null)}
        currentColor={selectedAnnotation?.highlightColor}
        onColorChange={updateColor}
        onDelete={deleteSelectedAnnotation}
      />
    </div>
  );
}

function applyAnnotation(container: HTMLElement, annotation: Annotation, selectAnnotation: () => void) {
  // We split the annotation into ranges based on block elements as we can't wrap across blocks
  // Instead we wrap each block separately
  const ranges = findAnnotationRanges(container, annotation);
  ranges.forEach(range => {
    const mark = createMarkElement(annotation);
    mark.addEventListener('click', (event) => {
      event.stopPropagation(); // Prevents the click event in case this is nested in another mark
      selectAnnotation();
    });
    const content = range.extractContents();
    mark.append(content);
    // find any nested mark children and remove them
    mark.querySelectorAll('mark').forEach(nestedMark => {
      const content = nestedMark.childNodes;
      nestedMark.replaceWith(...content);
    });

    range.insertNode(mark);
  });
}

function createMarkElement(annotation: Annotation): HTMLElement {
  const mark = document.createElement('mark');
  const colorClass = COLOR_OPTIONS[annotation.highlightColor].className;
  mark.className = `annotation-highlight ${colorClass} cursor-text hover:cursor-pointer active:cursor-text font-medium`;
  mark.dataset.annotationId = annotation.clientExamQuestionAnnotationId.toString();
  return mark;
}

function findAnnotationRanges(container: HTMLElement, annotation: Annotation): Range[] {
  const initialRange = findAnnotationRange(container, annotation);

  const walker = document.createTreeWalker(
    initialRange.commonAncestorContainer,
    NodeFilter.SHOW_TEXT,
    {
      acceptNode: (node) => initialRange.intersectsNode(node)
        ? NodeFilter.FILTER_ACCEPT
        : NodeFilter.FILTER_REJECT
    }
  );

  const blockGroups = new Map<Element, Text[]>();
  if (walker.currentNode !== initialRange.startContainer) {
    walker.nextNode();
  }

  let currentNode = walker.currentNode as Text;

  while (currentNode) {
    const blockParent = walker.currentNode.parentElement?.closest('p, div, article, section');
    if (blockParent) {
      if (!blockGroups.has(blockParent)) {
        blockGroups.set(blockParent, []);
      }
      blockGroups.get(blockParent)?.push(currentNode);
    }
    currentNode = walker.nextNode() as Text;
  }

  const blocks = Array.from(blockGroups.keys());
  // Single block case - return original range
  if (blocks.length === 1) {
    return [initialRange];
  }

  const ranges: Range[] = [];

  blocks.forEach((block, index) => {
    const range = document.createRange();
    const textNodes = blockGroups.get(block);

    if (!textNodes) {
      throw new Error('Text nodes not found for block');
    }

    if (index === 0) {
      // First block - from selection start to block end
      range.setStart(initialRange.startContainer, initialRange.startOffset);
      range.setEnd(textNodes[textNodes.length - 1], textNodes[textNodes.length - 1].length);
      ranges.push(range);
    } else if (index === blocks.length - 1) {
      // Last block - from block start to selection end
      range.setStart(textNodes[0], 0);
      range.setEnd(initialRange.endContainer, initialRange.endOffset);
      ranges.push(range);
    } else {
      // Middle blocks - entire content
      range.setStart(textNodes[0], 0);
      range.setEnd(textNodes[textNodes.length - 1], textNodes[textNodes.length - 1].length);
      ranges.push(range);
    }
  });

  console.assert(
    ranges.join('') === initialRange.toString(),
    `Ranges do not match original selection "${ranges.join('')} "!== "${initialRange.toString()}"`
  );

  return ranges;
}

function findAnnotationRange(container: HTMLElement, annotation: Annotation): Range {
  const walker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT);
  walker.nextNode();
  let currentOffset = 0;

  // Helper function to find node and offset
  const findNodeAndOffset = (targetOffset: number): [Node, number] => {
    let currentNode: Node | null = walker.currentNode;

    while (currentNode) {
      const nodeLength = currentNode.textContent?.length || 0;
      const nodeEndOffset = currentOffset + nodeLength;

      if (currentOffset <= targetOffset && nodeEndOffset >= targetOffset) {
        return [currentNode, targetOffset - currentOffset];
      }

      currentOffset += nodeLength;
      currentNode = walker.nextNode();
    }

    throw new Error(`Could not find text position at offset ${targetOffset}`);
  };

  const offsetEnd = annotation.offset + annotation.selection.length;

  const [startNode, startNodeOffset] = findNodeAndOffset(annotation.offset);
  const [endNode, endNodeOffset] = findNodeAndOffset(offsetEnd);

  const range = document.createRange();
  range.setStart(startNode, startNodeOffset);
  range.setEnd(endNode, endNodeOffset);

  console.assert(
    normalizeSelection(range) === annotation.selection,
    `Range does not match original selection "${normalizeSelection(range)}" !== "${annotation.selection}"`
  );

  return range;
}

function getTextOffset(container: HTMLElement, range: Range): number {
  const walker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT, null);

  let currentOffset = 0;
  let currentNode = walker.nextNode();
  while (currentNode) {
    if (currentNode === range.startContainer) {
      return currentOffset + range.startOffset;
    }
    currentOffset += currentNode.textContent?.length || 0;
    currentNode = walker.nextNode();
  }
  return currentOffset;
}

/**
 * Normalize selection text to have single new lines
 * @param selection
 */
function normalizeSelection(selection: { toString: () => string }): string {
  return selection.toString().replace(/\n+/g, '\n');
}

function getCurrentHighlightColor(): HighlightColorId {
  return Number.parseInt(sessionStorage.getItem('annotation-color') ?? '', 10) as HighlightColorId || 1;
}

// Deep Comparison of props, because we're going to change the rendered html, and we don't want it to re-render
const MemoizedBlocksRenderer = memo<RendererProps>(
  BlocksRenderer,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
    JSON.stringify(prevProps.config) === JSON.stringify(prevProps.config)
);

export interface HighlightableBlocksProps extends RendererProps {
  saveAnnotation: (annotation: Annotation) => Promise<Annotation>;
  initialAnnotations?: Annotation[];
  deleteAnnotation: (annotationId: number) => Promise<void>;
}

export default HighlightableBlocks;
