import { TypoHeading, TypoCaption, TypoCallout, TypoTitle } from '@/components/Typography.tsx';
import Button from '@/components/Button.tsx';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';
import { isDataProp, DataProp } from '@/utils/helpers.tsx';
import useCheckoutAction from '@/hooks/useCheckoutAction.ts';

const PackagesList = ({ list }: PackagesListProps) => (
  <>
    <TypoHeading className="font-medium text-neutral-text-strong ml-10 mb-2">
      Packages
    </TypoHeading>
    <div className="grid-cols-1 space-y-2">
      {list.map((p) => <Package key={p.productId} {...p} />)}
    </div>
  </>
);

const Package = ({ name, price, description }: PackageOption) => {
  const { checkout, loading } = useCheckoutAction(price?.productPriceId ?? 0);
  const priceColorClass = price?.price ? 'text-info' : 'text-neutral-text-softer';

  return (
    <div className="p-2 flex justify-between border-2 border-shade-10 rounded-lg">
      <div className="flex flex-1 flex-col p-4 pl-6">
        <TypoHeading className="font-medium text-neutral-text-strong mb-4">
          {name}
        </TypoHeading>
        {isDataProp(description) && (
          <BlocksRenderer
            data={description}
            config={{
              paragraph: {
                className: 'text-neutral-text-soft font-medium',
                actionsClassNames: {
                  alignment: 'text-{alignment}',
                },
              },
            }} />
        )}
      </div>
      <div className="flex flex-col items-center justify-center min-w-44 min-h-56 p-2.5 rounded-lg bg-neutral gap-3">
        <div className="flex items-end gap-1">
          <TypoTitle variant="secondary" className={`font-bold ${priceColorClass}`}>{formatPrice(price)}</TypoTitle>
          <TypoCallout variant="secondary" className="text-neutral-text-weak font-bold">USD</TypoCallout>
        </div>
        <Button color="success" onClick={() => checkout()} disabled={loading}>
          Get Access
        </Button>
        <TypoCaption className="font-semibold">Pay once, own it forever</TypoCaption>
      </div>
    </div>
  );
};

const formatPrice = (data: PackageOption['price']) => {
  if (!data) return '$0';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(data.price);
};

export interface PackageOption {
  productId: number;
  name: string;
  description: DataProp | null;
  price: {
    productPriceId: number;
    price: number;
  } | null;
}

interface PackagesListProps {
  list: Array<PackageOption>
}

export default PackagesList;
