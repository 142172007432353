import { useMemo } from 'react';
import { IQuestion } from '@/types/question.types.ts';
import { DataProp } from 'editorjs-blocks-react-renderer';
import { TypoCallout, TypoHeading2 } from '@/components/Typography';
import { BaseAnswer } from '@/pages/TestPage/TestAnswer.tsx';
import { CheckSquare, DeleteSquare } from '@/components/Icons.tsx';
import Tag from '@/components/Tag.tsx';
import AnswerTextField from './AnswerTextField';
import AnswerDirections from './AnswerDirections.tsx';

interface AnswerSectionProps {
  currentQuestion: IQuestion<string | DataProp>;
}

const AnswersReviewSection = ({ currentQuestion }: AnswerSectionProps) => {
  const {
    answers = [],
    examAnswerId: selectedAnswerId,
    position,
    directions,
    questionType
  } = currentQuestion ?? {};

  const isQuestionCorrect = useMemo(() => {
    const selectedAnswer = answers.find(a => a.examAnswerId === selectedAnswerId);
    return selectedAnswer?.isCorrect;
  }, [answers, selectedAnswerId]);

  return (
    <section className="flex-1 flex flex-col gap-6 pb-10 px-16 overflow-auto">
      <div className="sticky top-0 z-10 pt-10 flex justify-between items-center bg-neutral">
        <TypoHeading2 className="inline-block py-1 px-3 rounded-xs bg-neutral-canvas text-neutral-canvas-text">
          {position + 1}.
        </TypoHeading2>
        <div className={`flex gap-2 items-center ${isQuestionCorrect ? 'text-success' : 'text-danger'}`}>
          <TypoCallout className="text-neutral-text-soft">{isQuestionCorrect ? 'Correct' : 'Incorrect'}</TypoCallout>
          {isQuestionCorrect ? <CheckSquare /> : <DeleteSquare />}

        </div>
      </div>
      <div className="flex flex-col gap-10 py-11 px-[3.8rem]">
        <AnswerDirections directions={directions as DataProp} isSelectType={questionType === 'SELECT'} />
        {
          questionType === 'SELECT'
            ? (
              <ul className="flex flex-col gap-3">
                {
                  (answers as IQuestion['answers']).map((item, index) =>
                    <AnswerItem
                      key={item.examAnswerId}
                      selectedAnswerId={selectedAnswerId}
                      index={index}
                      item={item}
                    />
                  )
                }
              </ul>
            )
            : (
              <AnswerTextField
                currentQuestion={currentQuestion as IQuestion<string>}
                incorrect={!isQuestionCorrect}
                readOnly
              />
            )
        }
      </div>
    </section>
  );
};

interface AnswerItemProps {
  selectedAnswerId?: number;
  index: number;
  item: IQuestion['answers'][0];
}

const AnswerItem = ({ selectedAnswerId, index, item }: AnswerItemProps) => {
  const { examAnswerId, isCorrect } = item;
  const isSelected = selectedAnswerId === examAnswerId;

  const type = useMemo(() => {
    if (isCorrect) return 'correct';
    if (isSelected) return 'incorrect';
    return 'neutral';
  }, [isCorrect, isSelected]);

  return (
    <li className="relative">
      <BaseAnswer
        type={type}
        answer={item.answer}
        index={index}
      />
      {isSelected && <Tag title="your answer" className="absolute -top-2 -right-8" />}
    </li>
  );
};

export default AnswersReviewSection;
