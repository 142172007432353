import { useState, ButtonHTMLAttributes } from 'react';
import NavButton from './Navigation/NavButton.tsx';
import { Superscript } from './Icons.tsx';
import Popover from './Popover.tsx';
import ReferenceImage from '@/assets/reference.png';
import ReferenceImage2x from '@/assets/reference@2x.png';
import { TypoFootnote } from '@/components/Typography.tsx';

const ReferenceButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>();

  return (
    <>
      <NavButton {...props} ref={setAnchor} Icon={Superscript} onClick={() => setOpen(x => !x)}>reference</NavButton>
      <ReferenceDialog
        open={open}
        anchor={anchor}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

interface DialogProps {
  open: boolean;
  onClose: () => void;
  anchor?: Element | null;
}

const ReferenceDialog = ({ open, anchor, onClose }: DialogProps) => (
  <Popover
    open={open}
    title="Reference Sheet"
    anchor={anchor}
    placement="auto"
    onClose={onClose}
    width="45rem"
  >
    <img src={ReferenceImage} srcSet={`${ReferenceImage} 1x, ${ReferenceImage2x} 2x`} alt="Math Reference" />

    <div className="flex flex-col gap-1 text-neutral-text mt-2">
      <TypoFootnote>The number of degrees of arc in a circle is 360.</TypoFootnote>
      <TypoFootnote>The number of radians of arc in a circle is 2&pi;.</TypoFootnote>
      <TypoFootnote>The sum of the measures in degrees of the angles of a triangle is 180.</TypoFootnote>
    </div>
  </Popover>
);

export default ReferenceButton;
