import { toast, ToastOptions } from 'react-toastify';
import { TypoCallout, TypoHeading } from '@/components/Typography.tsx';

const notify = (title: string, message?: string | null, options?: ToastOptions) => toast(
  () => (
    <div className="flex flex-col gap-1">
      {Boolean(title) && <TypoHeading className="font-medium mr-5">{title}</TypoHeading>}
      {Boolean(message) && <TypoCallout>{message}</TypoCallout>}
    </div>
  ),
  options,
);

export const dismissAllNotifications = () => toast.dismiss();

export default notify;
