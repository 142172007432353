import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataProp } from 'editorjs-blocks-react-renderer';
import { AddBookmark, Strikethrough } from '@/components/Icons';
import Separator from '@/components/Separator';
import { TypoHeading, TypoHeading2 } from '@/components/Typography';
import { NumericBoolean } from '@/types/common.types.ts';
import { IQuestion } from '@/types/question.types.ts';
import AnswerSelect from './AnswerSelect';
import AnswerTextField from './AnswerTextField';
import AnswerDirections from './AnswerDirections.tsx';
import { useAnswerMutation } from '@/hooks/exam/useAnswerMutation.ts';

interface AnswerSectionProps {
  currentQuestion: IQuestion<string | DataProp>;
}

const AnswersSection = ({ currentQuestion }: AnswerSectionProps) => {
  const {
    examAnswerId: selectedAnswerId,
    position,
    examQuestionId,
    questionType,
    isMarked,
    directions,
  } = currentQuestion ?? {};
  const isSelectType = questionType === 'SELECT';
  const { examComponentId } = useParams();
  const [showCrossButton, setShowCrossButton] = useState(false);
  const { answerCallback, loading } = useAnswerMutation(Number(examComponentId));

  const onMarkForReview = useCallback(async () => {
    const input = { examQuestionId, examAnswerId: selectedAnswerId, isMarked: Number(!isMarked) as NumericBoolean };
    await answerCallback(input);
  }, [answerCallback, selectedAnswerId, examQuestionId, isMarked]);

  return (
    <section className="flex-1 flex flex-col gap-6 py-10 px-16 overflow-auto">
      <div className="sticky top-0 max-w-fit z-10">
        <TypoHeading2 className="inline-block py-1 px-3 rounded-xs bg-neutral-canvas text-neutral-canvas-text">
          {position + 1}.
        </TypoHeading2>
      </div>
      <div className="flex flex-col gap-10 py-11 px-[3.8rem]">
        <div className="flex flex-col">
          <div className="flex items-center justify-between gap-2">
            <button
              onClick={onMarkForReview}
              className={`
                flex items-center text-left
                ${isMarked ? 'text-primary' : 'text-neutral-text'}
                ${loading ? 'cursor-progress' : ''}
              `}
            >
              <AddBookmark width="48" height="48" className="p-2" />
              <TypoHeading className="">Mark for Review</TypoHeading>
            </button>
            {
              isSelectType && (
                <button onClick={() => setShowCrossButton(current => !current)}>
                  <Strikethrough
                    width="40"
                    height="40"
                    className={`p-2 ${showCrossButton ? 'text-primary' : 'text-neutral-action'}`}
                  />
                </button>
              )
            }
          </div>
          <Separator />
          <AnswerDirections directions={directions as DataProp} isSelectType={isSelectType} />
        </div>
        <div className="flex flex-col gap-3">
          {
            isSelectType
              ? <AnswerSelect currentQuestion={currentQuestion as IQuestion} showCrossButton={showCrossButton} />
              : <AnswerTextField currentQuestion={currentQuestion as IQuestion<string>} />
          }
        </div>
      </div>
    </section>
  );
};

export default AnswersSection;
