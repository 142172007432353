import { CSSProperties, forwardRef, Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { PopperChildrenProps, usePopper } from 'react-popper';
import { TypoHeading, TypoTitle } from './Typography.tsx';
import { DeleteSquare } from './Icons.tsx';

export interface PopoverProps {
  heading?: string;
  title: string;
  open: boolean;
  width?: number | string;
  onClose: () => void;
  anchor?: Element | null;
  placement: PopperChildrenProps['placement'],
  children: ReactNode;
  disableAwayClick?: boolean;
  className?: string;
  unmount?: boolean;
  draggable?: boolean;
}

const Popover = ({
  title,
  heading,
  open,
  placement,
  anchor: referenceElement,
  width = '38rem',
  onClose,
  disableAwayClick = false,
  className = '',
  unmount,
  draggable = false,
  children,
}: PopoverProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLSpanElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'offset',
        options: { offset: [0, 8] },
      },
    ],
  });

  const Wrapper = draggable ? Draggable : 'div';

  return (
    <Transition
      as={Fragment}
      show={open}
      unmount={unmount}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Wrapper
        className={`relative bg-neutral2 text-left px-8 pt-6 pb-4 shadow-popover rounded-md z-20 ${className}`}
        ref={setPopperElement}
        style={{ width, ...styles.popper }}
        {...attributes.popper}
      >
        {!disableAwayClick && referenceElement && popperElement ? (
          <ClickOutsideListener
            anchorElement={referenceElement}
            element={popperElement}
            callback={onClose}
          />
        ) : null}
        <div
          className={`flex items-start justify-between mb-2 ${
            draggable ? 'cursor-grab active:cursor-grabbing' : ''
          }`}
        >
          <div>
            {heading ? (
              <TypoTitle className="text-neutral2-text mb-4">
                {heading}
              </TypoTitle>
            ) : null}
            <TypoHeading className="text-neutral2-text">{title}</TypoHeading>
          </div>
          <button onClick={onClose}>
            <DeleteSquare className="text-neutral2-canvas" />
          </button>
        </div>
        <div>{children}</div>
        {!draggable && (
          <span
            className="popper-arrow"
            ref={setArrowElement}
            style={styles.arrow}
          >
            <svg className="text-neutral2" height={15} viewBox="0 0 36 15">
              <path
                id="decoration"
                d="M36 15L0 15L15.3425 1.36218C16.8581 0.0150323 19.1419 0.0150323 20.6575 1.36218L36 15Z"
                fill="currentColor"
              />
            </svg>
          </span>
        )}
      </Wrapper>
    </Transition>
  );
};

interface ClickOutsideProps {
  anchorElement: Element;
  element: HTMLDivElement;
  callback: () => void;
}

export const ClickOutsideListener = ({ anchorElement, element, callback }: ClickOutsideProps) => {
  const onClickOutside = useCallback((event: MouseEvent) => {
    if (anchorElement.contains(event.target as Node)) return;
    if (element.contains(event.target as Node)) return;

    callback();
  }, [element, anchorElement, callback]);

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, [onClickOutside]);

  return null;
};

interface DraggableProps {
  children: ReactNode;
  style?: CSSProperties;
}

const Draggable = forwardRef<HTMLDivElement, DraggableProps>((props, ref) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const onMouseDown = (event: React.MouseEvent) => {
    const isGrab = (event.target as HTMLDivElement).matches('.cursor-grab');
    if (!isGrab) return;

    setIsDragging(true);
    setDragStart({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
    event.preventDefault(); // Prevent text selection or drag and drop
  };

  const onMouseMove = useCallback((event: MouseEvent) => {
    if (!isDragging) return;

    // Clamp mouse positions to prevent dragging out of viewport
    const safeMargin = 40; // Allow the element to "slightly" leave the screen
    const clampedX = Math.min(Math.max(event.clientX, safeMargin), window.innerWidth - safeMargin);
    const clampedY = Math.min(Math.max(event.clientY, safeMargin), window.innerHeight - safeMargin);


    setPosition({
      x: clampedX - dragStart.x,
      y: clampedY - dragStart.y,
    });
  }, [isDragging, dragStart]);

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    } else {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, onMouseMove]);

  return (
    <div
      ref={ref}
      {...props}
      style={{
        ...props.style,
        position: 'absolute',
        top: `${position.y}px`,
        // We would have problems with popovers that use the `left` property, but we don't have any ATM
        right: `${-position.x}px`,
        cursor: isDragging ? 'grabbing' : undefined,
      }}
      onMouseDown={onMouseDown} />
  );
});

export default Popover;
