import { forwardRef } from 'react';

const NativePlayer = forwardRef<HTMLVideoElement, PlayerProps>(({ src, poster, subtitles, className }, ref) => (
  <div className={className}>
    <video
      ref={ref}
      src={src}
      crossOrigin="anonymous"
      className="w-full h-auto rounded-xl drop-shadow aspect-video bg-common-black"
      controls
      poster={poster || undefined}
      preload="metadata"
    >
      {subtitles && (
        <track
          src={subtitles.src}
          label={subtitles.label}
          kind="subtitles"
          srcLang={subtitles.srclang}
          default
        />
      )}
      Your browser does not support the video tag.
    </video>
  </div>
));

export interface PlayerProps {
  src?: string;
  poster?: string | null;
  subtitles?: { src: string; label: string; srclang: string };
  className?: string,
}

export default NativePlayer;
