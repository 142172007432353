import { ReactNode, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavContainer from './NavContainer.tsx';
import NavButton from './NavButton.tsx';
import { TypoHeading } from '../Typography.tsx';
import { ExpandWindow } from '../Icons.tsx';
import Dialog from '@/components/Dialog.tsx';

interface TopBarProps {
  title: string;
  className?: string;
  isPreview?: boolean;
  children?: ReactNode;
  rightSlot?: ReactNode;
}

const TopBar = ({ title, className = '', isPreview, children, rightSlot }: TopBarProps) => (
  <NavContainer className={className}>
    <TypoHeading className="text-neutral2-text-softer flex-1">
      {title}
    </TypoHeading>
    {children}
    <div className="flex-1 flex justify-end">
      {rightSlot}
      {
        isPreview ? (
          <Link to="/dashboard">
            <NavButton Icon={ExpandWindow}>exit</NavButton>
          </Link>
        ) : <ExitTest />
      }
    </div>
  </NavContainer>
);

const ExitTest = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <>
      <NavButton Icon={ExpandWindow} onClick={() => setOpen(true)}>exit</NavButton>
      <Dialog
        title="Are you sure?"
        description="You’re about to exit the current test."
        onAction={() => navigate(`/dashboard`)}
        open={open}
        onClose={() => setOpen(false)}
        cancelText="Stay"
        actionText="Exit!"
      />
    </>
  );
};

export default TopBar;
