import { ReactNode } from 'react';
import { TypoBody, TypoHeading } from '@/components/Typography.tsx';

interface ContentBoxProps {
  title: string;
  description: string;
  actionSlot?: ReactNode;
  darkMode?: boolean;
  className?: string;
}

const ContentBox = ({ title, description, actionSlot, darkMode = true, className = '' }: ContentBoxProps) => {
  const titleColor = darkMode ? 'text-neutral2-text' : 'text-pink-text';
  const containerColor = darkMode
    ? 'bg-neutral2 text-neutral2-text-soft shadow-dark'
    : 'bg-pink/[0.65] text-pink-text-soft shadow-light backdrop-blur-[20px]';

  return (
    <div className={`w-98 rounded px-10 py-8 z-10 ${containerColor} ${className}`}>
      <TypoHeading className={`block font-medium mt-4 mb-5 ${titleColor}`}>{title}</TypoHeading>
      <TypoBody>{description}</TypoBody>
      {actionSlot}
    </div>
  );
};

export default ContentBox;
