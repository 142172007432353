import { useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import UPDATE_PASSWORD_MUTATION from '@/graphql/mutations/update-password.graphql';
import Button from '@/components/Button';
import TextField from '@/components/TextField';
import notify from '@/utils/notifications.tsx';

const UpdatePasswordForm = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { password: '', passwordConfirmation: '' },
    resolver: yupResolver(NewPassSchema),
  });

  const [updatePassword, { loading, data }] = useMutation(
    UPDATE_PASSWORD_MUTATION, {
      onCompleted: () => {
        reset();
        notify('Success!', 'The password was updated successfully!', { type: 'success' });
      },
    }
  );

  const onSubmit = useCallback(
    async ({ password = '' }) => {
      clearErrors();
      await updatePassword({ variables: { password } });
    },
    [updatePassword, clearErrors]
  );

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4"
    >
      <TextField
        {...register('password')}
        type="password"
        placeholder="my secret password"
        label="New Password"
        error={Boolean(errors?.password)}
        errorText={errors?.password?.message}
        required
      />
      <TextField
        {...register('passwordConfirmation')}
        type="password"
        placeholder="my secret password"
        label="Retype Password"
        error={Boolean(errors?.passwordConfirmation)}
        errorText={errors?.passwordConfirmation?.message}
        required
      />
      <div className="flex items-center justify-end gap-4">
        <Button type="submit" disabled={loading}>
          {loading ? 'Updating' : data?.updatePassword ? 'Updated' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

const NewPassSchema = yup.object().shape({
  password: yup.string().min(8).max(64).required(),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
});

export default UpdatePasswordForm;
