import { TypoFootnote } from '@/components/Typography.tsx';

interface TagProps {
  title: string;
  className?: string;
}

const Tag = ({ title, className }: TagProps) => {
  const style = `inline-block bg-secondary text-secondary-text font-bold px-3 py-1 rounded-lg ${className}`;

  return <TypoFootnote className={style}>{title}</TypoFootnote>;
};



export default Tag;
