import { createContext, ReactNode, useState } from 'react';

export type ClientToken = {
  authToken: string
  authTokenExpirationDate: string
  clientId: number
  lastAccess: string
}

interface AuthContextType {
  clientId: string | null;
  onLogin: (data: ClientToken) => void;
  onLogout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  clientId: null,
  onLogin: () => undefined,
  onLogout: () => undefined
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [clientId, setClientId] = useState(localStorage.getItem('clientId') || null);

  const onLogin = (data: ClientToken) => {
    localStorage.setItem('authToken', data.authToken);
    localStorage.setItem('clientId', JSON.stringify(data.clientId));
    setClientId(JSON.stringify(data.clientId));
  };

  const onLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('clientId');
    setClientId(null);
  };

  return (
    <AuthContext.Provider value={{ clientId, onLogin, onLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
