import { DataProp } from 'editorjs-blocks-react-renderer';

// eslint-disable-next-line react-refresh/only-export-components
export const toJSON = (string: string) => {
  try {
    return JSON.parse(string);
  }
  catch (e) {
    return string || '';
  }
};

// eslint-disable-next-line react-refresh/only-export-components
export function isDataProp(description: any): description is DataProp {
  return description?.blocks?.length > 0;
}

export type { DataProp };
