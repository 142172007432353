import { ButtonHTMLAttributes, ElementType, forwardRef } from 'react';

interface IActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  Icon?: ElementType;
  badge?: number;
}

const ActionButton = forwardRef<HTMLButtonElement, IActionButtonProps>((props, ref) => {
  const { Icon, className = '', type = 'button', badge, children, ...rest } = props;

  const classNames = `relative inline-flex items-center gap-2
    h-9 px-4 py-0.5 rounded-xs 
    bg-shade-5 hover:bg-shade-10 disabled:bg-shade-5 font-medium
    text-callout text-neutral-text-strong disabled:text-neutral-text-weak
    ${className}`;

  return (
    <button type={type} ref={ref} className={classNames} {...rest}>
      {Icon && <Icon width={16} className="text-neutral-icon" />}
      {children}
      {
        Boolean(badge) && (
          <span
            className="
            w-6 h-6 absolute -top-2 -right-3 flex items-center justify-center rounded-full
            text-caption font-semibold text-warning-text bg-warning"
          >
            {badge}
          </span>
        )
      }
    </button>
  );
});


export default ActionButton;
