import { Separator as SeparatorIllustration } from './Icons';

interface ISeparatorProps {
  className?: string;
}

const Separator = ({ className = '' } : ISeparatorProps) => (
  <div className={`w-full ${className}`}>
    <SeparatorIllustration width="100%" height="100%" />
  </div>
);

export const VerticalSeparator = () => <div className="w-1 h-full rounded-md bg-[#44413C]" />;

export default Separator;
