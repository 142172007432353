export const COLOR_OPTIONS = {
  1: {
    name: 'Yellow',
    hex: '#FFC857',
    className: 'bg-warning text-warning-text hover:bg-warning-hover hover:text-warning-hover-text',
    selection: 'selection:bg-warning selection:text-warning-text',
    id: 1,
  },
  2: {
    name: 'Pink',
    hex: '#FB6376',
    className: 'bg-primary text-primary-text hover:bg-primary-hover hover:text-primary-hover-text',
    selection: 'selection:bg-primary selection:text-primary-text',
    id: 2,
  },
  3: {
    name: 'Green',
    hex: '#5AA05E',
    className: 'bg-success text-success-text hover:bg-success-hover hover:text-success-hover-text',
    selection: 'selection:bg-success selection:text-success-text',
    id: 3,
  },
  4: {
    name: 'Blue',
    hex: '#058ED9',
    className: 'bg-info text-info-text hover:bg-info-hover hover:text-info-hover-text',
    selection: 'selection:bg-info selection:text-info-text',
    id: 4,
  },
  5: {
    name: 'Red',
    hex: '#D63718',
    className: 'bg-danger text-danger-text hover:bg-danger-hover hover:text-danger-hover-text',
    selection: 'selection:bg-danger selection:text-danger-text',
    id: 5,
  }
};