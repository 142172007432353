import { CloseButtonProps, ToastContainer, ToastContainerProps, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DeleteSquare } from '@/components/Icons.tsx';

const ToastNotifications = (props: ToastContainerProps) => (
  <ToastContainer
    className="!w-100"
    toastClassName={
      (context) =>
        `${getClassName(context?.type ?? 'default')}
        relative flex py-4 mb-4 px-6 rounded-md overflow-hidden cursor-pointer`
    }
    bodyClassName={() => ''}
    position="top-right"
    newestOnTop
    icon={false}
    hideProgressBar
    autoClose={4000}
    closeButton={CloseButton}
    {...props}
  />
);

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <button className="absolute right-4 top-4" onClick={closeToast}>
    <DeleteSquare className="w-5 h-5 text-inherit" />
  </button>
);

const getClassName = (type: TypeOptions) => {
  switch (type) {
  case 'success':
    return 'bg-success text-success-text';
  case 'error':
    return 'bg-danger text-danger-text';
  case 'info':
    return 'bg-info text-info-text';
  case 'warning':
    return 'bg-warning text-warning-text';
  case 'default':
    return 'bg-neutral3 text-neutral3-text';
  }
};


export default ToastNotifications;
