import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link, Navigate, useParams } from 'react-router-dom';
import { CollectionProps, CollectionItem, CollectionItemProps } from '@/components/VideoCollectionsList.tsx';
import { HistoryLoader } from '@/components/Loader.tsx';
import { TypoHeading2 } from '@/components/Typography.tsx';
import { ArrowLeft2 } from '@/components/Icons.tsx';
import VIDEO_COLLECTION_QUERY from '@/graphql/queries/video-collection.graphql';

const VideoCollection = () => {
  const { collectionId = '' } = useParams();
  const { loading, ...collection } = useVideoCollection(Number(collectionId));

  if (!collectionId) return <Navigate to="/video-tutorials" replace />;

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        Video Tutorials
      </TypoHeading2>
      <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
        {loading ? <Loading /> : <Content {...collection} />}
      </div>
    </>
  );
};

const Loading = () => (
  <>
    <HistoryLoader />
    <HistoryLoader />
    <HistoryLoader />
  </>
);

const Content = ({ name, items }: CollectionProps) => (
  <>
    <div className="flex items-center gap-x-3 py-2 mb-8">
      <Link
        to="/video-tutorials"
        className="flex flex-col md:flex-row items-center rounded px-4 py-2 gap-x-2 bg-shade-5"
      >
        <ArrowLeft2 width={16} height={16} /><span className="text-common-white text-callout">Back</span>
      </Link>
      <strong className="font-primary text-body py-2">{name}</strong>
    </div>
    <div className="flex flex-wrap -mx-3">
      {
        items?.map((item) =>
          <CollectionItem key={item.id} className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6" {...item} />
        )
      }
    </div>
  </>
);

const useVideoCollection = (id: number): CollectionProps & { loading: boolean } => {
  const { data, loading } = useQuery<QueryResult>(VIDEO_COLLECTION_QUERY, {
    variables: { videoSubtypeId: id }
  });

  return useMemo(() => {
    const name = data?.videoSubtype.name || '';
    const items: CollectionItemProps[] = (data?.videoSubtype.videos ?? []).map(v => ({
      id: v.videoId,
      name: v.name,
      image: v.image,
      type: 'video',
    }));

    return { id, name, items, loading };
  }, [data?.videoSubtype, id, loading]);
};

interface QueryResult {
  videoSubtype: {
    videoTypeId: number;
    name: string;
    videos: Array<{
      videoId: number;
      name: string;
      image: string;
    }>;
  }
}

export default VideoCollection;
