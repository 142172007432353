import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import useAuth from '@/hooks/useAuth.ts';
import LOGOUT_MUTATION from '@/graphql/mutations/logout.graphql';

const useLogout = () => {
  const auth = useAuth();
  const [execute, { client, loading }] = useMutation(LOGOUT_MUTATION);

  const logout = useCallback(async () => {
    try {
      await execute();
      await client.clearStore();
      auth.onLogout();
    }
    catch (e) {
      console.error(e);
    }
  }, [auth, client, execute]);

  return { logout, loading };
};

export default useLogout;
