import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import CLIENT_EXAMS_QUERY from '@/graphql/queries/client-exams.graphql';
import TestHistory, { TestHistoryProps } from '@/components/TestHistory.tsx';
import { TypoBody, TypoHeading } from '@/components/Typography.tsx';
import ActionButton from '@/components/ActionButton.tsx';
import { ArchiveDocument } from '@/components/Icons.tsx';
import Tabs from '@/components/Tabs.tsx';
import { HistoryLoader } from './Loader';

interface TestTabsProps {
  full?: boolean;
  defaultIndex?: number;
}

const TestTabs = ({ defaultIndex, full = false }: TestTabsProps) => {
  const barSlot = !full && <TypoHeading className="text-neutral2-text font-medium">Your Tests</TypoHeading>;
  const barStyle = full ? '!justify-center' : 'flex-row-reverse';

  return (
    <Tabs
      barSlot={barSlot}
      barStyle={barStyle}
      defaultIndex={defaultIndex}
      tabs={
        [
          {
            title: 'In Progress',
            content: <TestTabContent full={full} tab="active" />,
          },
          {
            title: 'Completed',
            content: <TestTabContent full={full} tab="completed" />,
          },
        ]
      } />
  );
};

interface TabContentProps extends TestTabsProps {
  tab: 'active' | 'completed';
}

const TestTabContent = ({ full, tab }: TabContentProps) => {
  const { exams, loading } = useExamHistory(tab, full);
  const top4 = exams.slice(0, 4);

  return (
    <>
      <TypoBody className="mb-4">
        Track your progress, resume ongoing tests, or review completed ones. Every step is a leap towards your goal!
      </TypoBody>
      {
        loading
          ? <HistoryLoader />
          : <TestHistory tests={full ? exams : top4} />
      }
      {
        !full && exams.length > 4 &&
        <div className="flex justify-center">
          <Link to={`/dashboard/tests#${tab}`}>
            <ActionButton Icon={ArchiveDocument}>View All</ActionButton>
          </Link>
        </div>
      }
    </>
  );
};

interface ClientExamsResponse {
  clientExamId: number;
  created: string;
  updated: string;
  isCompleted: boolean;
  exam: {
    examId: number;
    name: string;
    examType: {
      examTypeId: number;
    }
  };
  components: {
    clientExamComponentId: number;
    scaledScore: number;
  }[];
}

const useExamHistory = (tab: TabContentProps['tab'], full?: boolean) => {
  const limit = full ? 999 : 5;
  const { data, loading } = useQuery(CLIENT_EXAMS_QUERY, {
    variables: { isCompleted: Number(tab === 'completed'), limit },
  });

  const exams: TestHistoryProps['tests'] = useMemo(() =>
    (data?.clientExams as ClientExamsResponse[] ?? []).map((exam) => ({
      examId: exam.exam.examId,
      clientExamId: exam.clientExamId,
      title: exam.exam.name,
      datetime: exam.updated || exam.created,
      isCompleted: exam.isCompleted,
      totalScore: exam.components
        .reduce((acc: number, current) => acc + current.scaledScore, 0) ?? 0,
    })), [data?.clientExams]);

  return {
    exams,
    loading,
  };
};

export default TestTabs;
