import { useRef, useState, UIEvent } from 'react';
import { Link } from 'react-router-dom';
import { TypoBody, TypoCallout, TypoHeading, TypoTitle } from './Typography';
import DefaultThumbnail from '@/assets/video-thumbnail.png';
import {
  ArrowLeft,
  ArrowRight,
  Calc,
  CalendarSchedule,
  ChessRook,
  EditText,
  LanguageBook,
  OpenBook,
} from '@/components/Icons.tsx';

const VideoCollectionsList = ({ list }: VideoCollectionsListProps) => (
  <div className="grid-cols-1 divide-y divide-neutral-canvas2 divide-opacity-10">
    {
      list.map((collection) =>
        <Collection key={collection.id} {...collection} />
      )
    }
  </div>
);

const Collection = ({ name, items }: CollectionProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkScrollPosition = (event: UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, clientWidth } = event.currentTarget;
    setIsAtStart(scrollLeft === 0);
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -scrollContainerRef.current.clientWidth / 3, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: scrollContainerRef.current.clientWidth / 3, behavior: 'smooth' });
    }
  };

  return (
    <div className="flex flex-col py-14 gap-8 first:pt-0 last:pb-0">
      <div className="flex items-center justify-between">
        <TypoHeading className="text-neutral-text-strong">{name}</TypoHeading>
        <div className="flex">
          <button
            className="bg-green-500 text-primary p-2 disabled:text-shade-30"
            disabled={isAtStart}
            onClick={scrollLeft}>
            <ArrowLeft width={24} height={24} />
          </button>
          <button
            className="bg-green-500 text-primary p-2 disabled:text-shade-30"
            disabled={isAtEnd}
            onClick={scrollRight}>
            <ArrowRight width={24} height={24} />
          </button>
        </div>
      </div>
      <div className="flex gap-x-6 overflow-hidden" ref={scrollContainerRef} onScroll={checkScrollPosition}>
        {
          items?.map((item) =>
            <CollectionItem key={item.id} {...item} />
          )
        }
      </div>
    </div>
  );
};

export const CollectionItem = ({
  id,
  type,
  name,
  image,
  icon,
  className = 'w-full sm:w-1/2 lg:w-2/5',
}: CollectionItemProps & { className?: string }) => (
  <Link className={`flex flex-col gap-3 flex-shrink-0 ${className}`} to={`/video-tutorials/${type}/${id}`}>
    {
      icon
        ? <CollectionCard icon={icon} name={name} />
        : <img src={image || DefaultThumbnail} alt="Video Thumbnail" className="rounded-xl drop-shadow w-full" />
    }
    <TypoCallout className="px-2 text-neutral-text-soft font-medium leading-5">{name}</TypoCallout>
  </Link>
);

const CollectionCard = ({ icon, name }: { icon: string, name: string }) => {
  const { Icon, title, logo } = getCardData(icon);
  let grad = 'bg-gradient-to-b from-[#420D0F] to-[#C1272D]';
  if (icon === 'BLUEBOOK') {
    grad = 'bg-gradient-to-b from-[#0A3C6E] to-[#0A3C6E]';
  }

  return (
    <div className="flex flex-col items-center gap-2 p-2 bg-[#E1CDC3] rounded-xl">
      <TypoBody className="text-neutral-canvas-text font-bold" variant="secondary">{title}</TypoBody>
      <div className={`flex items-center justify-center rounded-3xl w-16 h-16 ${grad}`}>
        {
          Icon
            ? <Icon className="text-[#E1CDC3]" />
            : <TypoTitle variant="secondary" className="font-bold">{/\d+$/.exec(name?.trim())?.[0] ?? '1'}</TypoTitle>
        }
      </div>
      <TypoCallout className="text-neutral-canvas-text">{logo || 'SATPRAC'}</TypoCallout>
    </div>
  );
};


const getCardData = (icon: string): { Icon?: any, title: string, logo?: string } => {
  const dataMap = {
    GRAMMAR: { Icon: EditText, title: 'Grammar' },
    MATH: { Icon: Calc, title: 'Math' },
    READING: { Icon: OpenBook, title: 'Reading' },
    SCHEDULING: { Icon: CalendarSchedule, title: 'Scheduling' },
    STRATEGY: { Icon: ChessRook, title: 'Strategy' },
    STUDYING: { Icon: LanguageBook, title: 'Studying' },
    PRACTICE: { title: 'Practice Test' },
    BLUEBOOK: { title: 'Practice Test', logo: 'BLUEBOOK' },
  };

  return dataMap[icon as keyof typeof dataMap] || { title: 'Practice Test' };
};

export interface CollectionItemProps extends CollectionProps {
  type: 'collection' | 'video';
}

export interface CollectionProps {
  id: number;
  name: string;
  icon?: string | null;
  image?: string | null;
  items?: Array<CollectionItemProps>;
}

export interface VideoCollectionsListProps {
  list: Array<CollectionProps>
}

export default VideoCollectionsList;
