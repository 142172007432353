import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import PASS_RESET_MUTATION from '@/graphql/mutations/password-reset.graphql';
import Button from '@/components/Button';
import { ResetPasswordIllustration } from '@/components/Icons';
import Separator from '@/components/Separator';
import { TypoTitle, TypoBody } from '@/components/Typography';
import TextField from '@/components/TextField';
import ActionButton from '@/components/ActionButton.tsx';

const PasswordReset = () => {
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(PassResetSchema),
  });

  const [passReset, { loading }] =
    useMutation(PASS_RESET_MUTATION, {
      onCompleted: (data) => setSuccess(data?.passwordReset),
      onError: () => setSuccess(false),
    });

  const onSubmit = useCallback(
    async ({ email = '' }) => {
      clearErrors();
      await passReset({ variables: { email } });
      reset();
    },
    [passReset, clearErrors, reset]
  );

  if (success) return <SuccessResetScreen />;

  return (
    <div className="w-full max-w-[23.75rem]">
      <div className="flex gap-1 items-center justify-between">
        <TypoTitle className="text-neutral-text">Password Reset</TypoTitle>
        <Link to="/sign-in" className="flex-shrink-0">
          <ActionButton>Sign In</ActionButton>
        </Link>
      </div>
      <Separator className="mt-2.5 mb-4" />
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <TextField
          {...register('email')}
          type="email"
          placeholder="john.doe@gmail.com"
          label="Email"
          error={Boolean(errors?.email)}
          errorText={errors?.email?.message}
          required
        />
        <div className="flex items-center justify-end gap-4">
          <Button type="submit" disabled={loading}>
            Reset
          </Button>
        </div>
      </form>
    </div>
  );
};

const SuccessResetScreen = () => (
  <div className="flex flex-col items-center justify-center gap-4 max-w-[23.75rem]">
    <ResetPasswordIllustration />
    <TypoTitle className="text-neutral-text-strong">
      Check Your Email
    </TypoTitle>
    <TypoBody className="text-neutral-text font-medium text-center">
      It seems you have forgotten your password. Check your email and click on
      RESET PASSWORD so you can start SATPrac-ticing again!
    </TypoBody>
  </div>
);

const PassResetSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export default PasswordReset;
