import Button from '@/components/Button.tsx';
import { useMutation } from '@apollo/client';
import GET_PDF_MUTATION from '@/graphql/mutations/get-pdf-report.graphql';

const PdfReportButton = ({ clientExamId }: { clientExamId: number }) => {
  const [getPdf, { loading }] = useMutation(GET_PDF_MUTATION, {
    variables: { clientExamId },
    onCompleted: (data) => {
      if (data?.filePath) {
        const link = document.createElement('a');
        link.href = `https://${import.meta.env.VITE_API_ORIGIN}`.concat(data.filePath);
        link.click();
      }
    },
    onError: (apolloError) => console.log(apolloError),
  });

  return <Button loading={loading} onClick={() => getPdf()}>Download Exam Report</Button>;
};

export default PdfReportButton;
