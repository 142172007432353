import { NavLink, useLocation } from 'react-router-dom';
import { TypoHeading2 } from '@/components/Typography.tsx';
import { ArrowLeft2 } from '@/components/Icons';
import TestTabs from '@/components/TestTabs.tsx';

const AllTests = () => {
  const { hash } = useLocation();
  const defaultIndex = hash ? TABS.indexOf(hash.slice(1)) : 0;

  return (
    <>
      <div className="flex items-center gap-4 px-10 pt-6 pb-4">
        <NavLink to="/dashboard" className="p-2">
          <ArrowLeft2 className="text-secondary" />
        </NavLink>
        <TypoHeading2 className="text-neutral-text-strong font-medium">
          Your Tests
        </TypoHeading2>
      </div>
      <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
        <TestTabs full defaultIndex={defaultIndex} />
      </div>
    </>
  );
};

const TABS = ['active', 'completed'];

export default AllTests;
