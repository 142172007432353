import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { TypeOptions } from 'react-toastify';
import TestTabs from '@/components/TestTabs.tsx';
import Separator from '@/components/Separator.tsx';
import TestList, { TestListProps } from '@/components/TestList.tsx';
import { TypoBody, TypoHeading, TypoHeading2 } from '@/components/Typography.tsx';
import notify, { dismissAllNotifications } from '@/utils/notifications.tsx';
import MissingTests from '@/components/MissingTests.tsx';
import AVAILABLE_EXAMS_QUERY from '@/graphql/queries/available-exams.graphql';
import CLIENT_EXAMS_QUERY from '@/graphql/queries/client-exams-short.graphql';
import ORDERS_QUERY from '@/graphql/queries/user-orders.graphql';

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = Number(searchParams.get('order_id'));
  const { data } = useQuery(ORDERS_QUERY, { skip: !orderId });
  const orders = useMemo(() => data?.user?.orders ?? [], [data]);
  const currentOrder = orders.find((o: { orderId: number }) => o.orderId === orderId);

  useEffect(() => {
    let timeoutId: any;

    if (currentOrder) {
      const { product } = currentOrder?.productPrice ?? {};
      const { type, message } = getOrderStatus(currentOrder.status);

      notify(product.name, message, { type });
      timeoutId = setTimeout(() => setSearchParams(''), 3000);
    }

    return () => {
      if (currentOrder) dismissAllNotifications();
      clearTimeout(timeoutId);
    };
  }, [currentOrder, orderId, setSearchParams]);

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        Dashboard
      </TypoHeading2>
      <TestSection />
    </>
  );
};

const TestSection = () => {
  const { exams, examsLoading } = useTestsData();
  const setExams = exams.filter(e => e.subtitle === 'SET');
  const fullExams = exams.filter(e => e.subtitle !== 'SET');

  return (
    <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
      <TypoHeading className="text-neutral-text font-medium py-2">
        Practice Makes Perfect
      </TypoHeading>
      <TypoBody className="mb-8">
        Take one of your full-length practice tests or work through one of your SATPrac packs
        and take a step towards perfection!
      </TypoBody>

      {exams.length === 0 && <MissingTests text="No tests available." />}
      {fullExams.length > 0 && (
        <div className="mt-4">
          <TypoBody className="text-neutral-text font-medium py-2">Full-length Exams</TypoBody>
          <TestList loading={examsLoading} list={fullExams} />
        </div>
      )}
      {setExams.length > 0 && (
        <div className="mt-4">
          <TypoBody className="text-neutral-text font-medium py-2">Question Sets and SATPrac Packs</TypoBody>
          <TestList loading={examsLoading} list={setExams}/>
        </div>
      )}
      <Separator className="my-14" />
      <TestTabs />
    </div>
  );
};

const useTestsData = () => {
  const { data: examsData, loading: examsLoading } = useQuery(AVAILABLE_EXAMS_QUERY);
  const { data: clientExamsData, loading: clientExamsLoading } = useQuery(CLIENT_EXAMS_QUERY);

  const clientExams = useMemo(() => clientExamsData?.clientExams as any[] ?? [], [clientExamsData?.clientExams]);
  const exams: TestListProps['list'] = useMemo(() =>
    (examsData?.exams ?? []).map((exam: ExamProps) => {
      const taken = clientExams.find(e => e.exam.examId === exam.examId);

      return {
        id: exam.examId,
        title: exam.name,
        subtitle: exam.examType.name,
        isActive: taken && !taken.isCompleted,
        isCompleted: taken?.isCompleted,
      };
    }), [examsData?.exams, clientExams]);

  const uniqueExams = exams.reduce((acc, current) => {
    if (!acc.find((exam) => exam.id === current.id)) {
      acc.push(current);
    }

    return acc;
  }, [] as TestListProps['list']);

  return {
    exams: uniqueExams,
    examsLoading: examsLoading || clientExamsLoading,
  };
};

interface ExamProps {
  examId: number;
  name: string;
  examType: { name: string; }
}

enum OrderStatus {
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED'
}

const getOrderStatus = (status: OrderStatus) => {
  let type: TypeOptions;
  let message: string;

  switch (status) {
  case OrderStatus.PAID:
    type = 'success';
    message = 'Product purchased successfully!';
    break;
  case OrderStatus.FAILED:
    type = 'error';
    message = 'Order failed!';
    break;
  case OrderStatus.PENDING:
    type = 'warning';
    message = 'Order is pending!';
    break;
  case OrderStatus.REFUNDED:
    type = 'info';
    message = 'Order refunded!';
    break;

  default:
    type = 'default';
    message = `Order status: ${status}`;
    break;
  }

  return { type, message };
};

export default Dashboard;
