import { useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '@/components/Loader.tsx';
import TestScreen from './TestScreen.tsx';
import CLIENT_EXAM_QUERY from '@/graphql/queries/client-exam.graphql';
import COMPLETE_COMPONENT_MUTATION from '@/graphql/mutations/complete-component.graphql';
import useExamComponent from '@/hooks/exam/useExamComponent.ts';
import { ComponentProps } from '@/types/component.types.ts';
import { DataProp } from 'editorjs-blocks-react-renderer';
import { AnnotationsContextProvider } from '@/components/Annotations';

const TestActivePage = () => {
  const { examId, examComponentId, question } = useParams();
  const { loading, ...examComponentProps } = useExamComponent(Number(examComponentId));

  if (loading) return <Loader />;

  return (
    <TestActive
      examId={Number(examId)}
      examComponentId={Number(examComponentId)}
      question={Number(question)}
      {...examComponentProps}
    />
  );
};

interface TestActiveProps extends ComponentProps<DataProp> {
  examId: number;
  examComponentId: number;
  question: number;
}

const TestActive = ({ examId, examComponentId, clientExamId, duration, ...props }: TestActiveProps) => {
  const navigate = useNavigate();
  const { data: clientExamData, refetch, loading } = useQuery(CLIENT_EXAM_QUERY, {
    variables: { clientExamId },
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const accommodationFactor = useMemo(() =>
    clientExamData?.clientExam?.accommodationFactor ?? 1, [clientExamData?.clientExam?.accommodationFactor]);

  const [completeComponent, { loading: completeLoading }] = useMutation(COMPLETE_COMPONENT_MUTATION, {
    variables: { examComponentId },
    onError: () => navigate('/dashboard'),
  });

  const onComplete = useCallback(async (breakTime?: number) => {
    await completeComponent();
    const { data: { clientExam } } = await refetch();

    if (!clientExam.isCompleted) {
      if (breakTime && breakTime > 0) navigate(`/tests/${examId}/break`, { state: { breakTime } });
      else navigate(`/tests/${examId}`);
    } else {
      navigate(`/review/${examId}/${clientExamId}`);
    }
  }, [clientExamId, completeComponent, examId, navigate, refetch]);

  const onQuestionChange = useCallback((questionNumber: number) => {
    navigate(`/tests/${examId}/${examComponentId}/${questionNumber}`, {
      state: { examComponentId },
    });
  }, [examComponentId, examId, navigate]);

  if (loading || completeLoading) return <Loader />;

  return (
    <AnnotationsContextProvider>
      <TestScreen
        onCompleteExam={onComplete}
        onQuestionChange={onQuestionChange}
        clientExamId={clientExamId}
        duration={Math.floor(duration * accommodationFactor)}
        {...props}
      />
    </AnnotationsContextProvider>
  );
};

export default TestActivePage;
