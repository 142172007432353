import { createPortal } from 'react-dom';
import Logo from '@/assets/logo.svg';

const Loader = ({ className='' }) => {
  const classNames = `
    fixed inset-0 text-neutral2-text flex items-center justify-center ${className}`;

  return (
    createPortal(
      <div className={classNames}>
        <div className="flex flex-col gap-2 items-center">
          <img className="h-20 animate-bounce" src={Logo} alt="SATPrac logo" />
          <strong>Loading...</strong>
        </div>
      </div>
      , document.body)
  );
};

export const Spinner = ({ className='' }) =>
  <svg className={`animate-spin ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    {/* eslint-disable-next-line max-len */}
    <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>;

export const TestItemLoader = ({ withPrice= false }) => (
  <div className="flex items-center justify-between gap-6 py-2 px-4 rounded-xl bg-neutral">
    {/* Illustration */}
    <div className="animate-pulse bg-neutral2 w-[3.5rem] h-[3.5rem] m-2 rounded" />
    <div className="flex flex-col gap-1 flex-1">
      {/* Test name */}
      <div className="rounded-sm animate-pulse bg-neutral2 h-5 w-20"></div>
      {/* Test subtitle */}
      <div className="rounded-sm animate-pulse bg-neutral2 h-4 w-12"></div>
    </div>
    <div className="flex items-center gap-2">
      {/* price tag */}
      {withPrice && <div className="rounded-xs animate-pulse bg-neutral2 w-9 h-4"></div>}
      {/* Buy button */}
      <div className="animate-pulse bg-neutral2 w-20 h-11 rounded"></div>
    </div>
  </div>
);

export const HistoryLoader = () => (
  <div className="py-4">
    <div className="flex items-center justify-between p-2 pl-6 rounded-lg bg-neutral">
      <div className="rounded-sm animate-pulse bg-neutral2 h-5 w-44"></div>
      <div className="animate-pulse bg-neutral2 w-28 h-11 rounded"></div>
    </div>
  </div>
);

export default Loader;
