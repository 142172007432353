import { Strikethrough } from '../../components/Icons';
import { TypoHeading } from '../../components/Typography';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';
import { DataProp } from 'editorjs-blocks-react-renderer';

interface BaseAnswerProps {
  index?: number;
  answer: DataProp;
  type?: 'correct' | 'incorrect' | 'crossed' | 'neutral';
  className?: string;
  isCorrect?: boolean;
}

interface ActiveTestAnswerProps extends BaseAnswerProps {
  showCrossButton: boolean;
  loading?: boolean;
  crossLoading: boolean;
  onToggle: () => void;
  onCrossToggle: () => void;
}

const ActiveTestAnswer = (props: ActiveTestAnswerProps) => {
  const {
    index = 0,
    answer,
    type,
    showCrossButton,
    loading = false,
    onToggle,
    crossLoading,
    onCrossToggle,
    className = ''
  } = props;

  const selected = type === 'correct';
  const crossed = type === 'crossed';

  const answerBtnStyles = `
  relative flex gap-4 items-center text-left w-full py-3 px-6 rounded
  ${loading ? 'cursor-progress' : ''} ${className}
  `;

  const crossBtnStyles = `
    absolute -right-14 top-[calc(50%-1.5rem)]
    ${crossed ? 'text-primary' : 'text-neutral-action'}
    ${crossLoading ? 'cursor-progress' : ''}
  `;

  return (
    <div className="relative">
      <button className="w-full" onClick={onToggle} disabled={crossed || loading}>
        <BaseAnswer answer={answer} type={type} index={index} className={answerBtnStyles} />
        {crossed && <CrossLine />}
      </button>
      {
        showCrossButton &&
         <button className={crossBtnStyles} onClick={onCrossToggle} disabled={selected}>
           <Strikethrough width="48" height="48" className="p-2" />
         </button>
      }
    </div>
  );
};

export const BaseAnswer = ({ answer, index = 0, type = 'neutral', className }: BaseAnswerProps) => {
  const styles = `relative flex gap-4 items-center text-left w-full py-3 px-6 rounded ${COLORS[type]} ${className}`;

  return (
    <div className={styles}>
      <TypoHeading
        className="border-4 border-shade-10 text-center text-shade-50 w-10 h-10 shrink-0 pl-px rounded-full"
      >
        {toLetter(index)}
      </TypoHeading>
      {answer?.blocks?.length ? <BlocksRenderer data={answer} /> : 'No valid answer'}
    </div>
  );
};

const CrossLine = () => <div className="absolute top-1/2 -left-2.5 h-1 -mt-0.5 -right-2.5 rounded-xs bg-shade-70" />;

const COLORS = {
  correct: 'bg-success hover:bg-success-hover text-success-text hover:text-success-hover-text',
  incorrect: 'bg-danger hover:bg-danger-hover text-danger-text hover:text-danger-hover-text',
  neutral: 'bg-neutral3 hover:bg-neutral3-hover text-neutral3-text hover:text-neutral3-hover-text',
  crossed: 'bg-shade-10 text-shade-70'
};

const toLetter = (idx: number) => (idx + 10).toString(36).toUpperCase();

export default ActiveTestAnswer;
