import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import CLIENT_EXAM_COMPONENT_QUERY from '@/graphql/queries/client-exam-component.graphql';
import EXAM_COMPONENT_QUERY from '@/graphql/queries/exam-component.graphql';
import { IAnsweredQuestion, IQuestion } from '@/types/question.types.ts';
import { ComponentProps } from '@/types/component.types.ts';
import { toJSON } from '@/utils/helpers.tsx';

const useExamComponent = (examComponentId: number, clExamId?: number) => {
  const { data: clientData, loading: clientLoading } = useQuery(CLIENT_EXAM_COMPONENT_QUERY, {
    variables: { examComponentId, clientExamId: clExamId }
  });

  const { data, loading } = useQuery(EXAM_COMPONENT_QUERY, {
    variables: { examComponentId, reviewState: Boolean(clExamId) }
  });

  const {
    name= '',
    duration,
    questions,
    breakAfter,
    componentType,
    directions = ''
  } = data?.examsComponent as ComponentProps ?? {};

  const {
    clientExamId,
    questions: clientQuestions,
    durationElapsed
  } = clientData?.clientExamComponent as ComponentProps ?? {};

  const formattedQuestions: IQuestion[] = useMemo(() => {
    if (!questions) return [];

    return questions.map(({
      examQuestionId,
      question,
      directions,
      questionType,
      answers,
      explanation,
      ...rest
    }: IQuestion<string>) => ({
      examQuestionId,
      questionType,
      question: toJSON(question),
      directions: toJSON(directions),
      explanation: toJSON(explanation),
      answers: answers.map(a => ({
        ...a,
        answer: questionType === 'SELECT' ? toJSON(a.answer) : a.answer,
      })),
      ...rest,
      ...clientQuestions?.find((q: IAnsweredQuestion) => q.examQuestionId === examQuestionId )
    }));
  }, [questions, clientQuestions]);

  return {
    name,
    clientExamId,
    directions: toJSON(directions),
    questions: formattedQuestions,
    duration,
    durationElapsed,
    breakAfter,
    componentType,
    loading: loading || clientLoading,
  };
};

export default useExamComponent;
