import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { AnswerQuestionInputProps } from '@/types/question.types.ts';
import notify from '@/utils/notifications.tsx';
import ANSWER_QUESTION_MUTATION from '@/graphql/mutations/answer-question.graphql';
import CLIENT_EXAM_COMPONENT_QUERY from '@/graphql/queries/client-exam-component.graphql';

export const useAnswerMutation = (examComponentId: number) => {
  const [answerQuestion, { loading }] = useMutation(ANSWER_QUESTION_MUTATION);

  const answerCallback = useCallback(async (input: AnswerQuestionInputProps, shouldRefetch = true) => {
    try {
      const refetchQueries = shouldRefetch ? [{
        query: CLIENT_EXAM_COMPONENT_QUERY,
        variables: { examComponentId }
      }] : [];

      await answerQuestion({ variables: { input }, refetchQueries: refetchQueries });

      if (input?.answerInput?.length) {
        notify('Success!', 'Answer saved!', { type: 'success' });
      }
    }
    catch (error: any) {
      notify('Something went wrong!', error?.message ?? '', { type: 'error' });
    }
  }, [answerQuestion, examComponentId]);

  return { answerCallback, loading };
};
