import { ReactNode, useCallback, useEffect, useState } from 'react';
import { JobApplication } from '@/components/Icons.tsx';
import { TypoBody, TypoTitle } from '@/components/Typography.tsx';
import Button from '@/components/Button.tsx';
import VERIFY_EMAIL_MUTATION from '@/graphql/mutations/verify-email.graphql';
import { ApolloError, useMutation } from '@apollo/client';
import Loader from '@/components/Loader.tsx';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import useUser from '@/hooks/useUser.ts';
import useAuth from '@/hooks/useAuth.ts';
import type { ClientToken } from '@/AuthProvider.tsx';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('code');

  if (!email || !code) return <Navigate to="/" replace />;

  return (
    <VerifyEmailWrapper email={email}>
      <VerifyEmailInner email={email} code={code} />
    </VerifyEmailWrapper>
  );
};

const VerifyEmailWrapper = ({ email, children }: { email: string; children: ReactNode }) => {
  const { user, loading } = useUser();
  const auth = useAuth();

  if (loading) return <Loader />;
  if (user?.clientId && user?.email !== email) {
    return (
      <ContentWrapper>
        <TypoTitle className="text-danger text-center">
          Something went wrong!
        </TypoTitle>
        <TypoBody className="text-neutral-text font-medium text-center">
          You are logged in with another email: <strong>{user.email}</strong>.
          <br /> <br />
          Please sign out, so we can proceed with the verification of <strong>{email}</strong>.
        </TypoBody>
        <Button onClick={() => auth.onLogout()}>Sign Out</Button>
      </ContentWrapper>
    );
  }

  if (user?.isEmailVerified) return <Navigate to="/" replace />;

  return children;
};

const VerifyEmailInner = (props: { email: string; code: string }) => {
  const { email, code: verificationCode } = props;
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const [verifyEmail, { data, loading, error }] = useMutation(VERIFY_EMAIL_MUTATION, {
    variables: { input: { email, verificationCode } },
    onCompleted: (response) => setSuccess(Boolean(response.verifyEmail?.clientId)),
    onError: () => setSuccess(false)
  });

  const onSignIn = useCallback(() => {
    if (!auth.clientId) auth.onLogin(data?.verifyEmail as ClientToken);
    else navigate('/dashboard', { replace: true });
  }, [auth, data?.verifyEmail, navigate]);

  useEffect(() => {
    const abortController = new AbortController();

    const callback = async () => verifyEmail({
      context: {
        fetchOptions: { signal: abortController.signal }
      }
    });

    callback();

    return () => abortController.abort();
  }, [verifyEmail]);

  if (loading) return <Loader />;

  return (
    <ContentWrapper>
      {success ? <SuccessContent onSignIn={onSignIn} /> : <ErrorContent error={error} />}
    </ContentWrapper>
  );
};

const ContentWrapper = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col items-center justify-center gap-4 max-w-[23.75rem]">
    <JobApplication />
    {children}
  </div>
);

const ErrorContent = ({ error }: { error?: ApolloError }) => {
  const errors = error?.graphQLErrors[0]?.extensions?.fields ?? {};
  const errorText = Object.values(errors).join(', ');

  return (
    <>
      <TypoTitle className="text-danger">
        Email not verified!
      </TypoTitle>
      <TypoBody className="text-neutral-text font-medium text-center">
        {errorText}
      </TypoBody>
    </>
  );
};

const SuccessContent = ({ onSignIn }: { onSignIn: () => void }) => (
  <>
    <TypoTitle className="text-success">
      Email Verified!
    </TypoTitle>
    <TypoBody className="text-neutral-text font-medium text-center">
      Your journey to SAT dominance has now officially begun! Sign in to get the process rolling!
    </TypoBody>
    <Button onClick={onSignIn}>Sign In</Button>
  </>
);

export default VerifyEmail;
