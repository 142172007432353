import { lazy, Suspense } from 'react';
import NativePlayer, { PlayerProps } from './NativePlayer';

const HlsPlayer = lazy(() => import('./HLSPlayer'));

const hasNativeHlsSupport = document.createElement('video').canPlayType('application/vnd.apple.mpegurl');

const VideoPlayer = (props: PlayerProps) => {
  if (props.src?.endsWith('.m3u8') && !hasNativeHlsSupport) {
    console.info('Using HLS.js for HLS support');
    return (
      <Suspense fallback={<NativePlayer {...props} src="" />}>
        <HlsPlayer {...props} />
      </Suspense>
    );
  }

  console.info('Using native browser support for HLS');
  return <NativePlayer {...props} />;
};

export default VideoPlayer;
