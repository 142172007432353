import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Duration } from 'luxon';
import Button from '@/components/Button';
import { RestIllustration } from '@/components/Icons';
import Separator, { VerticalSeparator } from '@/components/Separator';
import { TypoHeading, TypoHeading2, TypoTitle3, TypoBody, TypoCaption } from '@/components/Typography';
import START_COMPONENT_MUTATION from '@/graphql/mutations/start-component.graphql';
import notify from '@/utils/notifications.tsx';

const BreakTime = () => {
  const { state } = useLocation();
  const params = useParams();
  const examId = Number(params?.examId);
  const navigate = useNavigate();
  const storageKey = `BREAK_TIME_LEFT:${examId}`;

  const [startExam, { loading }] = useMutation(START_COMPONENT_MUTATION, {
    variables: { examId },
  });

  const onContinue = useCallback(async () => {
    try {
      const { data: { startComponent } } = await startExam();
      const { examComponentId } = startComponent?.clientExamComponent ?? {};

      navigate(`/tests/${examId}/${examComponentId}/1`, {
        replace: true
      });

      localStorage.removeItem(storageKey);
    }
    catch (error) {
      let message;

      if (error instanceof Error) message = error.message;
      else message = String(error);

      notify('Something went wrong!', message, { type: 'error' });
    }
  }, [examId, navigate, startExam, storageKey]);

  return (
    // TODO: export/reuse components from TestInto component
    <div className="flex flex-col items-center justify-center gap-14 h-full w-full p-10">
      <div className="relative flex items-center gap-11">
        <div className="relative flex flex-col gap-6 items-center justify-center">
          <RestIllustration className="absolute -top-52 -mt-0.5" />
          <div className="flex flex-col items-center gap-2 w-64 px-4 py-8 rounded-xl bg-neutral2">
            <TypoHeading2 className="text-neutral2-text font-bold">Break Time</TypoHeading2>
            <LocalTimer
              storageKey={storageKey}
              defaultTime={state?.breakTime}
              onCompleted={onContinue}
            />
          </div>
          <Button color="secondary" onClick={onContinue} loading={loading}>Resume Test</Button>
        </div>
        <VerticalSeparator />
        <div className="flex flex-col gap-8 py-6">
          <div className="flex flex-col gap-2 max-w-xs">
            <TypoHeading className="text-neutral-text-strong">Brain Recess!</TypoHeading>
            <TypoBody className="text-neutral-text">
              Put your pencil down and give those neurons a breather. You’re doing amazing!
              You are welcome to restart as soon as you would like as you do your SATPrac practice tests.
              But, when taking your real SAT, you must wait until the clock counts down before you start back up!
            </TypoBody>
          </div>
          <Separator />
          <div className="flex flex-col gap-2 max-w-xs text-neutral-text">
            <TypoHeading className="text-neutral-text-strong">Rest & Recharge:</TypoHeading>
            <ol className="list-decimal pl-4 mb-2">
              <li>Stretch, hydrate, have a snack, and maybe do a little happy dance!</li>
              <li>Remember, it’s not about perfection. It’s about progress.</li>
              <li>Breathe in confidence, exhale doubt.</li>
              <li>Do not access your phone, smartwatch, textbooks, notes, or the internet.</li>
              <li>Do not eat or drink in the test room.</li>
            </ol>
            <TypoBody>
              See you in {Duration.fromObject({ seconds: state?.breakTime ?? TIMER_MAX_TIME }).toFormat('m')}!
              <br />
              <TypoCaption>(Or whenever you hit that ‘Resume Test’ button)</TypoCaption>
            </TypoBody>
            <TypoBody className="mt-2">P.S. Brains love breaks, so treat yours kindly!</TypoBody>
          </div>
        </div>
      </div>
    </div>
  );
};

interface LocalTimeProps {
  storageKey: string;
  defaultTime?: number;
  onCompleted: () => void;
}

const LocalTimer = ({ storageKey, defaultTime = TIMER_MAX_TIME, onCompleted }: LocalTimeProps) => {
  const initialTimer = Number(localStorage.getItem(storageKey) ?? defaultTime);
  const [timer, setTimer] = useState<number>(initialTimer);
  const timeText = timer > 0 ? Duration.fromObject({ seconds: timer }).toFormat('m:ss') : '00:00';

  const countTimer = useCallback(() => {
    if (timer > 0) {
      setTimer(prev => {
        localStorage.setItem(storageKey, String(prev - 1));
        return prev - 1;
      });
    }
    else onCompleted();
  }, [storageKey, onCompleted, timer]);

  useEffect(() => {
    const intervalId = setInterval(countTimer, 1000);
    return () => clearTimeout(intervalId);
  }, [countTimer]);

  return (
    <TypoTitle3 className="text-neutral2-text-strong">
      {timeText}
    </TypoTitle3>
  );
};

const TIMER_MAX_TIME = 900; //15 minutes

export default BreakTime;
