import { ArchiveDocument } from './Icons';
import { TypoCallout } from './Typography';

const MissingTests = ({ text = '' }) => (
  <div className="flex w-full max-w-xl justify-center items-center gap-4 px-8 py-4 mb-4 mt-8 rounded-lg bg-neutral">
    <ArchiveDocument className="text-neutral-icon" />
    <TypoCallout className="font-medium text-neutral-text-soft">{text}</TypoCallout>
  </div>
);

export default MissingTests;
