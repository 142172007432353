import { OwlIllustration } from '@/components/Icons.tsx';
import { TypoHeading } from '@/components/Typography.tsx';
import useUser from '@/hooks/useUser.ts';

const UserInfo = ({ className = '' }: { className?: string }) => {
  const { user } = useUser();

  return (
    <div className={`flex items-center gap-4 ${className}`}>
      <OwlIllustration width="40" height="40" className="rounded-full border-2 border-neutral2-text-softer" />
      <TypoHeading className="text-neutral2-text-softer">
        {user?.profileName ?? '-'}
      </TypoHeading>
    </div>
  );
};

export default UserInfo;
