import { Fragment, ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import { Transition } from '@headlessui/react';
import { ClickOutsideListener } from '@/components/Popover.tsx';
import { RemoveBin } from '@/components/Icons.tsx';
import type { HighlightColorId } from '@/types/question.types.ts';
import { COLOR_OPTIONS } from '@/components/Annotations/COLOR_OPTIONS.tsx';

const AnnotationPopup = ({
  open,
  anchor,
  onClose,
  currentColor,
  onColorChange,
  onDelete
}: AnnotationPopupProps) => (
  <Popper
    open={open}
    anchor={anchor}
    onClose={onClose}
  >
    {Object.values(COLOR_OPTIONS).map((color) => (
      <button
        key={color.id}
        title={color.name}
        className={`p-1 w-8 h-8 rounded-full ${color.id == currentColor ? 'border border-shade-30' : ''}`}
        onClick={() => onColorChange(color.id as HighlightColorId)}
      >
        <div className={`rounded-full p-1.5 w-full h-full ${color.className}`} />
      </button>
    ))}
    <div className="w-0.5 h-4 mx-1 rounded-full bg-shade-10" />
    <button
      title="Delete"
      className="flex items-center justify-center p-1 w-8 h-8 text-center rounded-full border border-shade-10"
      onClick={() => onDelete()}
    >
      <RemoveBin width={16} height={16} className="rounded-full" />
    </button>
  </Popper>
);



const Popper = ({
  open,
  anchor: referenceElement,
  onClose,
  children,
}: PopoverProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [0, 8] },
      },
    ],
  });

  return (
    <Transition
      as={Fragment}
      show={open}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        ref={setPopperElement}
        className={
          'flex flex-row items-center relative bg-neutral2 shadow-popover ' +
          'z-20 gap-1 text-neutral-text px-1.5 py-2.5 rounded-3xl'
        }
        style={styles.popper}
        {...attributes.popper}
      >
        {referenceElement && popperElement ? (
          <ClickOutsideListener
            anchorElement={referenceElement}
            element={popperElement}
            callback={onClose}
          />
        ) : null}
        {children}
      </div>
    </Transition>
  );
};

interface AnnotationPopupProps extends Omit<PopoverProps, 'children'> {
  currentColor?: HighlightColorId;
  onColorChange: (color: HighlightColorId) => void;
  onDelete: () => void;
}

interface PopoverProps {
  open: boolean;
  onClose: () => void;
  anchor?: Element | null;
  children: ReactNode;
}

export default AnnotationPopup;
