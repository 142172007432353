import { Link, useLocation, useParams } from 'react-router-dom';
import { DataProp } from 'editorjs-blocks-react-renderer';
import Button from '@/components/Button.tsx';
import { DeleteSquare, GraduationIllustration } from '@/components/Icons.tsx';
import Separator, { VerticalSeparator } from '@/components/Separator.tsx';
import { TypoBody, TypoCallout, TypoHeading, TypoHeading2 } from '@/components/Typography.tsx';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';
import PdfReportButton from '@/components/PdfReportButton.tsx';
import useExamData from '@/hooks/exam/useExamData.ts';
import { ExamType } from '@/types/common.types.ts';

type ReviewComponent = {
  examComponentId: number;
  name: string;
  description: DataProp | string;
  scaledScore: number;
  componentType: ExamType;
};

const TestReviewInit = () => {
  const params = useParams();
  const examId = Number(params?.examId);
  const clientExamId = Number(params?.clientExamId);
  const { examName, examType, examDescription, components, loading } = useExamData(examId, clientExamId);

  const totalRWScore = components
    .filter((c: ReviewComponent) => c.componentType === 'RW')
    .reduce((acc: number, current: ReviewComponent) => acc + current.scaledScore, 0);

  const totalMathScore = components
    .filter((c: ReviewComponent) => c.componentType === 'MATH')
    .reduce((acc: number, current: ReviewComponent) => acc + current.scaledScore, 0);

  return (
    <div className="flex flex-col items-center justify-center gap-14 h-full w-full p-10">
      <div className="relative flex items-center gap-11 w-full max-w-[720px]">
        <Link
          to="/dashboard"
          className="absolute left-full bottom-full text-neutral-canvas hover:text-neutral-canvas-hover"
        >
          <DeleteSquare />
        </Link>
        <GraduationIllustration className="flex-shrink-0" />
        <VerticalSeparator />
        <div className="flex flex-col gap-8 py-6 flex-1 max-w-sm">
          <div className="flex flex-col">
            {
              loading
                ? (
                  <div className="flex items-center h-8">
                    <span className="rounded-sm animate-pulse bg-neutral2 h-5 w-full" />
                  </div>
                )
                : <TypoHeading className="text-neutral-text-strong font-medium">{examName}</TypoHeading>
            }
            {
              examType !== 'SET' && !loading
                ? (
                  <div className="flex items-start justify-center gap-5 mt-6">
                    <ScoreCircle title="Exam Total" score={totalRWScore + totalMathScore} />
                    <ScoreCircle title="Reading and Writing" score={totalRWScore} />
                    <ScoreCircle title="Math" score={totalMathScore} />
                  </div>
                )
                : <div className="my-2"><RichDescription data={examDescription} /></div>
            }
          </div>
          <Separator />
          <TestReviewComponents list={components} loading={loading} />
          {examType !== 'SET' && (
            <>
              <Separator />
              <PdfReportButton clientExamId={clientExamId} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const RichDescription = ({ data }: { data: any }) => {
  if (!data) return null;

  if (data?.blocks?.length) {
    return (
      <BlocksRenderer
        data={data}
        config={{
          paragraph: {
            className: 'font-primary text-body text-neutral-text',
            actionsClassNames: {
              alignment: 'text-{alignment}',
            }
          },
        }}
      />
    );
  }

  return <TypoBody className="text-neutral-text">{data}</TypoBody>;
};

const TestReviewComponents = ({
  list = [],
  loading = false
}: {
  list?: ReviewComponent[];
  loading?: boolean
}) => {
  const { pathname } = useLocation();

  if (loading) return <Loader rows={4} />;

  return (
    <div className="flex flex-col gap-2 max-w-sm">
      {list.map((item, index) => (
        <div key={index} className="flex gap-3 justify-between items-center rounded-lg bg-neutral">
          <div className="flex flex-col text-neutral-text-softer">
            <TypoCallout>{item.name}</TypoCallout>
          </div>
          <Link to={`${item.examComponentId}/1`} state={{ from: pathname }}>
            <Button withArrow color="info">Review</Button>
          </Link>
        </div>
      ))}
    </div>
  );
};

const ScoreCircle = ({ title = 'Score', score = 0 }: { title?: string; score?: number }) => (
  <div className="flex flex-col items-center text-center max-w-[7rem]">
    <div className="w-18 h-18 flex items-center justify-center p-1 mb-2 bg-neutral-hover rounded-full">
      <TypoHeading2 className="text-primary font-semibold leading-none">{score}</TypoHeading2>
    </div>
    <span className="text-neutral-text">{title}</span>
  </div>
);

const Loader = ({ rows = 4 }) => (
  <div className="flex flex-col gap-2 w-full">
    {[...Array(rows).keys()].map((key) => (
      <div key={key} className="flex gap-3 items-center justify-between rounded-lg">
        <div className="rounded-sm animate-pulse bg-neutral2 h-5 flex-1"></div>
        <div className="animate-pulse bg-neutral2 w-28 h-11 rounded shrink-0"></div>
      </div>
    ))}
  </div>
);

export default TestReviewInit;
