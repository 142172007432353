import { ReactNode } from 'react';

export interface TypoProps {
  className?: string;
  variant?: 'primary' | 'secondary';
  children: ReactNode;
}

export interface TitleProps extends Omit<TypoProps, 'variant'> {
  variant?: 'primary' | 'secondary' | 'condensed';
}

export const TypoTitle3 = ({ className = '', variant = 'primary', children }: TitleProps) =>
  <h1 className={`font-${variant} text-title3 ${className}`}>{children}</h1>;
export const TypoTitle2 = ({ className = '', variant = 'primary', children }: TitleProps) =>
  <h2 className={`font-${variant} text-title2 ${className}`}>{children}</h2>;
export const TypoTitle = ({ className = '', variant = 'primary', children }: TitleProps) =>
  <h3 className={`font-${variant} text-title ${className}`}>{children}</h3>;

export const TypoHeading2 = ({ className = '', variant = 'primary', children }: TitleProps) => {
  const style = variant === 'condensed' ? 'font-medium' : '';
  return <strong className={`font-${variant} text-heading2 ${style} ${className}`}>{children}</strong>;
};
export const TypoHeading = ({ className = '', variant = 'primary', children }: TitleProps) => {
  const style = variant === 'condensed' ? 'font-medium' : '';
  return <strong className={`font-${variant} text-heading ${style} ${className}`}>{children}</strong>;
};

export const TypoBody = ({ className = '', variant = 'primary', children }: TypoProps) => {
  const style = variant === 'secondary' ? 'leading-7' : '';
  return <p className={`font-${variant} text-body ${style} ${className}`}>{children}</p>;
};
export const TypoCallout = ({ className = '', variant = 'primary', children }: TypoProps) =>
  <span className={`font-${variant} text-callout ${className}`}>{children}</span>;
export const TypoFootnote = ({ className = '', variant = 'primary', children }: TypoProps) =>
  <span className={`font-${variant} text-footnote ${className}`}>{children}</span>;
export const TypoCaption = ({ className = '', variant = 'primary', children }: TypoProps) =>
  <span className={`font-${variant} text-caption ${className}`}>{children}</span>;
