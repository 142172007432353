import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DataProp } from 'editorjs-blocks-react-renderer';
import { CollectionItemProps } from '@/components/VideoCollectionsList.tsx';
import { HistoryLoader } from '@/components/Loader.tsx';
import { TypoHeading, TypoHeading2 } from '@/components/Typography.tsx';
import { ArrowLeft2 } from '@/components/Icons.tsx';
import VIDEO_QUERY from '@/graphql/queries/video.graphql';
import VideoPlayer from '@/components/VideoPlayer';
import { toJSON } from '@/utils/helpers.tsx';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';

const Video = () => {
  const { videoId = '' } = useParams();
  const { video } = useVideo(Number(videoId));

  if (!videoId) return <Navigate to="/video-tutorials" replace />;

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        Video Tutorials
      </TypoHeading2>
      <div className="w-full bg-neutral2 text-neutral2-text-soft rounded px-10 pt-4 pb-8">
        {video == null ? <HistoryLoader /> : <Content {...video} />}
      </div>
    </>
  );
};

const Content = ({ description, image, name, stream, subtitles }: VideoItemWithSource) => {
  const navigate = useNavigate();
  const subtitleData = { src: subtitles, label: 'English (US)', srclang: 'en-US' };
  const hasDescription = description != null && description.blocks.length > 0;

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="flex flex-col md:flex-row items-center rounded px-4 py-2 gap-x-2 bg-shade-5 mt-2 mb-10"
      >
        <ArrowLeft2 width={16} height={16} /><span className="text-common-white text-callout">Back</span>
      </button>
      <div className="flex flex-col gap-y-3 mb-6">
        <VideoPlayer src={stream} poster={image} subtitles={subtitleData} className="w-full" />
        <span className="px-2 text-neutral-text-soft font-medium text-body">{name}</span>
      </div>
      {hasDescription && (
        <article className="flex flex-col gap-2">
          <TypoHeading className="font-medium">Description</TypoHeading>
          <BlocksRenderer
            data={description}
            config={{
              paragraph: {
                className: 'text-body font-primary',
                actionsClassNames: {
                  alignment: 'text-{alignment}',
                }
              },
            }}
          />
        </article>
      )}
    </>
  );
};

const useVideo = (videoId: number): { loading: boolean, video?: VideoItemWithSource } => {
  const { data, loading } = useQuery<QueryResult>(VIDEO_QUERY, {
    variables: { videoId }
  });

  return useMemo(() => ({
    loading,
    video: data?.video && {
      id: videoId,
      image: data.video.image,
      name: data.video.name,
      description: toJSON(data.video.description) || null,
      stream: data.video.stream,
      subtitles: data.video.subtitles,
      type: 'video',
    }
  }), [data?.video, loading, videoId]);
};

interface VideoItemWithSource extends CollectionItemProps {
  stream: string;
  subtitles: string;
  description: DataProp | null;
}

interface QueryResult {
  video: {
    image: string;
    name: string;
    description: string;
    stream: string;
    subtitles: string;
  };
}

export default Video;
