import { Link } from 'react-router-dom';
import { TypoFootnote } from '@/components/Typography.tsx';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-neutral shrink-0 p-4 border-y border-neutral">
      <div className="w-full max-w-5xl mx-auto flex items-center justify-between h-16">
        <TypoFootnote className="text-neutral2-text-softer">
          Copyright © {year} SATPrac. All rights reserved.
        </TypoFootnote>
        <div className="flex justify-end gap-4 text-neutral2-text-softer">
          <Link className="hover:underline" to="/terms">
            Terms
          </Link>
          <Link className="hover:underline" to="/privacy-policy">
            Privacy Policy
          </Link>
          <a className="hover:underline" href="https://idiottutor.com/" target="_blank">
            Idiot Tutor Mark on TikTok
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
