import notify from '@/utils/notifications.tsx';
import { useMutation } from '@apollo/client';
import CHECKOUT_MUTATION from '@/graphql/mutations/checkout.graphql';

const useCheckoutAction = (productPriceId: number) => {
  const [checkout, { loading, client }] = useMutation(CHECKOUT_MUTATION, {
    variables: { productPriceId },
    onCompleted: (data) => {
      const { order, checkout } = data.checkout;
      const { product } = order?.productPrice ?? {};

      if (order.status === 'PAID') {
        client.refetchQueries({ include: ['AvailableExams', 'PurchaseOptions', 'PurchaseOptionsForClient'] });
        notify(product.name || 'Success!', 'Product purchased successfully', { type: 'success' });
        return;
      }

      const url = checkout?.checkoutUrl ?? '';

      if (checkout.status === 'OPEN' && url) window.location.assign(url);
    },
    onError: (apolloError) => console.error(apolloError),
  });

  return { checkout, loading };
};

export default useCheckoutAction;
