import { useState } from 'react';
import { Highlighter } from '@/components/Icons.tsx';
import ActionButton from '@/components/ActionButton.tsx';
import { useAnnotationsContext } from './AnnotationsContext.tsx';
import Popover from '@/components/Popover.tsx';

const AnnotationsToggle = (props: AnnotationsToggleProps) => {
  const [visible, setVisible] = useAnnotationsContext();

  return (
    <div className="flex items-center gap-1">
      <ActionButton
        Icon={Highlighter}
        onClick={() => setVisible(current => !current)}
        badge={!visible && !props.disabled ? props.count : undefined}
        disabled={props.disabled}
      >
        {visible && 'hide'} annotations
      </ActionButton>
      <InfoTooltip />
    </div>
  );
};

const InfoTooltip = () => {
  const [visible, setVisible] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>();

  return (
    <>
      <button
        onClick={(event) => {
          setVisible(true);
          setAnchor(event.currentTarget);
        }}
        className={`flex 
          items-center justify-center p-1 w-8 h-8 text-center text-heading 
          font-bold border border-shade-10
        `}
      >?</button>
      <Popover anchor={anchor} title="Annotations" open={visible} onClose={() => setVisible(false)} placement="auto">
        <div className="p-2 text-neutral-text">
          <p className="mb-2">
            Annotations allow you to highlight portions of text within the Questions section.
            To start, click the 'annotations' button and select the text you want to mark. You can choose
            from several highlight colors to organize your notes. To delete an annotation, simply left-click
            it to open the annotation menu, then click the bin icon.
          </p>
          <p>
            Each question can include multiple annotations. When you revisit a question with annotations,
            the highlighted content will be visible. By clicking 'hide annotations,' all highlights will be
            hidden, and new annotations cannot be added. However, a number icon on the 'annotations' button
            will show the total annotations saved for each question. Click the button again at any time to
            redisplay the highlighted text.
          </p>
        </div>
      </Popover>
    </>
  );
};

interface AnnotationsToggleProps {
  count?: number;
  disabled?: boolean;
}

export default AnnotationsToggle;
