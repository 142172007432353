import Logo from '@/assets/logo.svg';
import { TypoCallout, TypoHeading } from '@/components/Typography.tsx';
import { Link } from 'react-router-dom';
import ActionButton from '@/components/ActionButton.tsx';

const Header = () => (
  <header className="fixed left-0 right-0 top-4 z-50 px-4">
    <div className="w-full max-w-5xl mx-auto flex items-center justify-between">
      <Link to="/" className="inline-flex items-center gap-1.5">
        <img className="h-12 mr-2.5" src={Logo} alt="SATPrac logo" />
        <TypoHeading variant="secondary">SAT</TypoHeading>
        <TypoHeading>Prac</TypoHeading>
      </Link>

      <div className="flex items-center gap-5">
        <a href="#pricing">
          <TypoCallout>Pricing</TypoCallout>
        </a>
        <Link to="/sign-in">
          <ActionButton type="button">Sign In</ActionButton>
        </Link>
      </div>
    </div>
  </header>
);

export default Header;
