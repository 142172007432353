import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ToastNotifications from '@/components/ToastNotifications.tsx';
import client from './graphql/client';
import App from './App.tsx';
import './index.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ApolloProvider client={client}>
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
          <ToastNotifications />
        </React.StrictMode>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </ApolloProvider>
);
