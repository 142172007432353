import { forwardRef, ElementType, ButtonHTMLAttributes } from 'react';

interface INavButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  Icon: ElementType;
}

const NavButton = forwardRef<HTMLButtonElement, INavButtonProps>((props, ref) => {
  const { children, Icon, className = '', type = 'button', ...rest } = props;

  const classNames = `inline-flex flex-col justify-center items-center gap-0.5 shrink-0 h-16 p-2 pb-0 rounded-xs
    bg-neutral2 hover:bg-neutral2-hover
    text-callout font-medium text-neutral2-text hover:text-neutral2-hover-text
    ${className}`;

  return (
    <button type={type} ref={ref} className={classNames} {...rest}>
      <Icon width={20} height={20} className="text-neutral-icon" />
      {children}
    </button>
  );
});

export default NavButton;
