import { useMutation } from '@apollo/client';
import RESEND_EMAIL_MUTATION from '@/graphql/mutations/resend-verification-email.graphql';
import notify from '@/utils/notifications.tsx';

const useEmailVerification = () => {
  const [resendEmailVerification, { loading }] = useMutation(RESEND_EMAIL_MUTATION, {
    onCompleted: () => {
      notify('Email Sent!', 'Please check your email!', { type: 'info' });
    },
    onError: (apolloError) => console.error(apolloError),
  });

  return { resendEmailVerification, loading };
};

export default useEmailVerification;
