import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export interface IVariant {
  variant?: 'unanswered' | 'answered' | 'current' | 'review';
}

export interface IQuestionButtonProps extends IVariant {
  number?: number;
  className?: string;
  onClick?: (number: number) => void;
  legend?: boolean;
}

const QuestionButton = ({
  variant = 'current',
  legend = false,
  number = 0,
  onClick,
  className = '',
}: IQuestionButtonProps) => {
  const { question } = useParams();
  const classNames = `relative flex items-center justify-center rounded-xs 
  font-medium text-heading ${legend ? 'w-8 h-4' : 'w-9 h-10'} ${VARIANTS[variant]} ${className}`;

  const handleClick = useCallback(() => {
    if (onClick) onClick(number);
  }, [number, onClick]);

  if (legend) {
    return (
      <span className={classNames}>
        <Decoration variant={variant} />
      </span>
    );
  }

  return (
    <button onClick={handleClick} className={classNames} disabled={Number(question) == number}>
      <Decoration variant={variant} />
      {number}
    </button>
  );
};

const Decoration = ({ variant }: IVariant) => {
  if (variant === 'answered') {
    return <span className="absolute w-2 h-2 -top-1 bg-neutral2 rotate-45" />;
  }

  if (variant === 'review') {
    return <span className="w-2.5 h-2.5 absolute -top-[0.3125rem] bg-danger border-2 border-neutral rounded-full" />;
  }

  return null;
};

const VARIANTS = {
  unanswered:
  'bg-neutral2-canvas text-neutral2-canvas-text hover:bg-neutral2-canvas-hover hover:text-neutral2-canvas-hover-text',
  answered: 'bg-primary text-primary-text hover:bg-primary-hover hover:text-primary-hover-text',
  current: 'bg-success text-success-text hover:bg-success-hover hover:text-success-hover-text',
  review: 'bg-warning text-warning-text hover:bg-warning-hover hover:text-warning-hover-text',
};

export default QuestionButton;
