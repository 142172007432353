import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '@/components/Loader.tsx';
import { TypoBody, TypoHeading, TypoHeading2 } from '@/components/Typography.tsx';
import PackagesList, { PackageOption } from '@/components/PackagesList.tsx';
import TextField from '@/components/TextField.tsx';
import Button from '@/components/Button.tsx';
import VideoPlayer from '@/components/VideoPlayer';
import PACKAGES_QUERY from '@/graphql/queries/purchase-options-for-client.graphql';
import DEFAULT_POSTER from '@/assets/video-thumbnail.png';
import VIDEO_SOURCE from '@/assets/introductory-video.webm';
import { CheckSquare } from '@/components/Icons.tsx';

const Packages = () => {
  const { list, loading, refetch } = usePackages();

  if (loading) return <Loader />;

  return (
    <>
      <TypoHeading2 className="text-neutral-text-strong font-medium px-10 pt-6 pb-4">
        SATPrac Packages
      </TypoHeading2>
      <div className="flex flex-col w-full bg-neutral2 text-neutral2-text-soft rounded px-2 pt-4 pb-8">
        <PackagesIntro />
        {list.length === 0 && <EmptyState />}
        {list.length > 0 && <PackagesList list={list} />}
        {list.length > 0 && <PromoCode refetchPackages={refetch} />}
      </div>
    </>
  );
};

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center gap-4 py-28">
    <div className="flex flex-col gap-12">
      {/*Todo: add something here*/}
    </div>
  </div>
);

const PackagesIntro = () => (
  <div className="p-10 pt-8">
    <VideoPlayer
      src={VIDEO_SOURCE}
      poster={DEFAULT_POSTER}
      className="w-full mb-8" />
    <TypoHeading className="text-neutral-text font-medium py-2">
      Why is SATPrac the right site for your test preparation needs?
    </TypoHeading>
    <TypoBody>
      The digital SAT is a game of skill and you win games of skill quite simply by practicing, practicing,
      and then practicing a little more. Take a test, see what you get wrong, see why you got it wrong,
      see how to get it right next time, review that information, and then repeat the process.
      That is how SATPrac is put together. It is a well-oiled machine that will put you on a path to victory.
      In this short video to the left, I walk you through how the site is laid out and how you can use it
      to put yourself in position to dominate the SAT!
    </TypoBody>
  </div>
);

const PromoCode = ({ refetchPackages }: { refetchPackages: UsePackagesOutput['refetch'] }) => (
  <div className="mt-8 px-10">
    <TypoHeading className="text-neutral-text font-medium py-2">
        Have a Promo Code?
    </TypoHeading>
    <TypoBody className="mb-8">
        Enter your unique code to avail exclusive discounts and make your test prep journey even more rewarding.
    </TypoBody>
    <PromoCodeForm onSubmit={refetchPackages} />
  </div>
);

const PromoCodeForm = ({ onSubmit }: { onSubmit: UsePackagesOutput['refetch'] }) => {
  const [loading, setLoading] = useState(false);
  const [lastSubmittedCode, setLastSubmittedCode] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    watch,
  } = useForm({ defaultValues: { code: '' }, resolver: yupResolver(PromoCodeSchema) });

  const submitCallback = useCallback(async ({ code = '' }) => {
    setLoading(true);
    await onSubmit({ code });
    setLoading(false);
    setLastSubmittedCode(code);
  }, [onSubmit]);

  const code = watch('code');

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit(submitCallback)}
      className="flex items-center gap-4 justify-between p-4 rounded-xl bg-neutral mt-4"
    >
      <TextField
        {...register('code')}
        type="text"
        placeholder="#SK123"
        label="Enter your Promo Code"
        error={Boolean(errors?.code)}
        errorText={errors?.code?.message}
        className="flex-1"
        onFocus={(event) => event.target.select()}
      />
      <Button className="w-32 mt-7"
        color="info"
        type="submit" loading={loading}>
        { isSubmitSuccessful && lastSubmittedCode == code
          ? <><CheckSquare width={24} height={24} /> Applied</>
          : <>Apply</> }
      </Button>
    </form>
  );
};

const usePackages = (): UsePackagesOutput => {
  const { data, loading, refetch } = useQuery<{ purchaseOptionsForClient: PackageOption[] }>(PACKAGES_QUERY);

  return useMemo(() => ({
    loading,
    refetch,
    list: (data?.purchaseOptionsForClient ?? [])
      // We filter out any packages that don't have a price, as they are supposed to appear only with promo code
      // See: https://incept.slack.com/archives/C05JFSSN7PY/p1725035841042909
      .filter(p => p?.price?.productPriceId)
      .map(p => ({
        ...p,
        description: JSON.parse(p.description as any),
      }))
      .sort((a, b) => (a.price?.price || 0) - (b.price?.price || 0))
  }), [data?.purchaseOptionsForClient, loading, refetch]);
};

const PromoCodeSchema = yup.object().shape({
  code: yup.string().max(45),
});

interface UsePackagesOutput {
  loading: boolean;
  list: PackageOption[];
  refetch: (variables?: { code: string }) => Promise<any>;
}

export default Packages;
