import { ReactNode, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Button from '@/components/Button';
import { DeleteSquare, SmartIllustration } from '@/components/Icons';
import Separator, { VerticalSeparator } from '@/components/Separator';
import { TypoHeading, TypoBody, TypoCallout } from '@/components/Typography';
import START_COMPONENT_MUTATION from '@/graphql/mutations/start-component.graphql';
import useExamData from '@/hooks/exam/useExamData.ts';
import BlocksRenderer from '@/components/BlocksRenderer.tsx';

const TestIntro = () => {
  const params = useParams();
  const navigate = useNavigate();
  const examId = Number(params?.examId);
  const [startExam, { data, loading }] = useMutation(START_COMPONENT_MUTATION, {
    variables: { examId },
    onError: () => navigate('/dashboard'),
  });

  const result = useMemo(() =>
    data?.startComponent?.clientExamComponent ?? {}, [data?.startComponent?.clientExamComponent]);

  const clientExamId = result?.clientExamId;
  const clientExamComponentId = result?.clientExamComponentId;

  useEffect(() => {
    const abortController = new AbortController();

    const callback = async () => startExam({
      context: {
        fetchOptions: { signal: abortController.signal }
      }
    });

    callback();

    return () => abortController.abort();
  }, [startExam]);

  const componentLink = result?.examComponentId ? `${result.examComponentId}/1` : '';

  return (
    <div className="flex flex-col items-center justify-center gap-14 h-full w-full p-10">
      <div className="relative flex items-center gap-11 w-full max-w-[700px]">
        <Link
          to="/dashboard"
          className="absolute left-full bottom-full text-neutral-canvas hover:text-neutral-canvas-hover"
        >
          <DeleteSquare />
        </Link>
        <SmartIllustration className="flex-shrink-0" />
        <VerticalSeparator />
        {
          loading || !clientExamId || !clientExamComponentId
            ? <Loader />
            : <Content
              examId={examId}
              clientExamId={clientExamId}
              clientExamComponentId={clientExamComponentId}
            />
        }
      </div>
      <Link to={`/tests/${examId}/${componentLink}`}>
        <Button className="w-48">
          Continue
        </Button>
      </Link>
    </div>
  );
};

interface ContentProps {
  examId: number;
  clientExamId: number;
  clientExamComponentId: number;
}

const Content = ({ examId, clientExamId, clientExamComponentId }: ContentProps) => {
  const { examName, examDescription, components, loading } = useExamData(examId, clientExamId);
  const currentComponent = useMemo(() =>
    components.find((c: any) => c.clientExamComponentId === clientExamComponentId),
  [clientExamComponentId, components]);

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col gap-8 py-6 flex-1">
      <div className="flex flex-col gap-2">
        <TypoHeading className="text-neutral-text-strong">{examName}</TypoHeading>
        {
          Boolean(examDescription) &&
          <RichDescription data={examDescription} className="font-primary text-body text-neutral-text">
            <TypoBody className="text-neutral-text">{examDescription}</TypoBody>
          </RichDescription>
        }
      </div>
      <Separator />
      <div className="flex flex-col gap-4">
        {
          Boolean(currentComponent?.name) &&
          <TypoBody className="text-neutral-text-strong">{currentComponent.name}</TypoBody>
        }
        {
          Boolean(currentComponent?.description) &&
          <RichDescription data={currentComponent.description} className="font-primary text-callout text-neutral-text">
            <TypoCallout className="text-neutral-text">{currentComponent.description}</TypoCallout>
          </RichDescription>
        }
      </div>
    </div>
  );
};

const RichDescription = ({
  data,
  className = '',
  children
}: {
  data: any;
  className?: string;
  children: ReactNode
}) => {
  if (data?.blocks?.length) {
    return (
      <BlocksRenderer
        data={data}
        config={{
          paragraph: {
            className,
            actionsClassNames: {
              alignment: 'text-{alignment}',
            }
          },
        }}
      />
    );
  }

  return children;
};

const Loader = () => (
  <div className="flex flex-col gap-8 py-6 flex-1">
    <div className="rounded-sm animate-pulse bg-neutral2 h-5"></div>
    <Separator />
    <div className="flex flex-col gap-2">
      <div className="rounded-sm animate-pulse bg-neutral2 h-5"></div>
      <div className="rounded-sm animate-pulse bg-neutral2 h-5"></div>
    </div>
  </div>
);

export default TestIntro;
