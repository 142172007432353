import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from 'react';

const AnnotationsContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>([false, () => false]);

export const AnnotationsContextProvider = ({ children }: { children: ReactNode }) => {
  const state = useState(false);

  return (
    <AnnotationsContext.Provider value={state}>
      {children}
    </AnnotationsContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAnnotationsContext = () => useContext(AnnotationsContext);
