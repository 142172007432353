import { Fragment } from 'react';
import { Dialog as UIDialog, Transition } from '@headlessui/react';
import { TypoBody, TypoHeading2 } from './Typography';
import Button from './Button';

export interface DialogProps {
  open: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onAction: () => void;
  cancelText?: string;
  actionText?: string;
  actionLoading?: boolean;
}

const Dialog = ({
  open,
  title,
  description,
  onClose,
  onAction,
  cancelText = 'Cancel',
  actionText = 'Action',
  actionLoading = false,
}: DialogProps) => (
  <Transition show={open} as={Fragment}>
    <UIDialog className="relative z-50" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-common-black/[.55]" aria-hidden="true" />
      </Transition.Child>

      <Transition.Child
        as="div"
        className="fixed inset-0 flex items-center justify-center p-4"
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <UIDialog.Panel className="w-full max-w-[28rem] px-8 py-6 flex flex-col gap-5 bg-neutral rounded-md">
          <div className="flex flex-col gap-3 pt-4 pb-2">
            <UIDialog.Title as={TypoHeading2} className="font-medium text-neutral-text-strong">
              {title}
            </UIDialog.Title>
            <UIDialog.Description as={TypoBody} className="font-medium text-neutral-text">
              {description}
            </UIDialog.Description>
          </div>
          <div className="flex gap-2 justify-end">
            <Button color="secondary" onClick={onClose}>{cancelText}</Button>
            <Button onClick={onAction} loading={actionLoading}>{actionText}</Button>
          </div>
        </UIDialog.Panel>
      </Transition.Child>
    </UIDialog>
  </Transition>
);

export default Dialog;
