import { Link } from 'react-router-dom';
import ContentBox from '@/pages/Home/ContentBox.tsx';
import Button from '@/components/Button.tsx';
import { TypoFootnote } from '@/components/Typography.tsx';

const Hero = () => (
  <section
    className="relative flex flex-col items-center justify-between landing-hero pt-36 pb-14 px-4 gap-10 h-screen"
  >
    <h1 className="font-primary text-title2 text-center">SATPrac - Digital SAT Practice</h1>

    <div className="flex flex-col items-center mb-8 z-20">
      <ContentBox
        className="mb-32"
        title={CONTENT.TITLE}
        description={CONTENT.DESCRIPTION}
        actionSlot={(
          <div className="flex items-center justify-end mt-10">
            <Link to="/sign-up">
              <Button type="button">Get Started Now</Button>
            </Link>
          </div>
        )}
      />
      <TypoFootnote className="text-neutral-text-weak">Scroll to learn more</TypoFootnote>
    </div>

    <div className="absolute left-1/2 -translate-x-1/2 bottom-24 z-10">
      <span className="block w-48 h-48 bg-[#594059] rounded-full blur-[100px]" />
    </div>
  </section>
);

export default Hero;

const CONTENT = {
  TITLE: 'Do you need a place to practice for the digital SAT?',
  DESCRIPTION: `SATPrac has you covered! The SATPrac site has practice tests,
  practice question bundles, answer explanations, and more to help you dominate the digital SAT!`
};
