import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import REGISTER_MUTATION from '@/graphql/mutations/register.graphql';
import { TypoBody, TypoTitle } from '@/components/Typography.tsx';
import Separator from '@/components/Separator.tsx';
import Button from '@/components/Button.tsx';
import ActionButton from '@/components/ActionButton';
import useAuth from '@/hooks/useAuth.ts';
import type { ClientToken } from '@/AuthProvider.tsx';
import TextField from '@/components/TextField';
import { JobApplication } from '@/components/Icons.tsx';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

const SignUp = () => {
  const auth = useAuth();
  const [success, setSuccess] = useState(false);

  const onRegisterCompleted = useCallback((data: { register: ClientToken }) => {
    const { register } = data;
    setSuccess(true);
    setTimeout(() => auth.onLogin(register), 3000);
  }, [auth]);

  if (success) return <Success />;

  return (
    <div className="w-full max-w-[46.75rem]">
      <div className="flex items-center justify-between">
        <TypoTitle className="text-neutral-text">Sign Up</TypoTitle>
        <Link to="/sign-in" className="flex-shrink-0">
          <ActionButton>Sign In</ActionButton>
        </Link>
      </div>
      <Separator className="mt-2.5 mb-4" />
      <SignUpForm onCompleted={onRegisterCompleted} />
    </div>
  );
};

const Success = () => (
  <div className="flex flex-col items-center justify-center gap-4 max-w-[23.75rem]">
    <JobApplication />
    <TypoTitle className="text-neutral-text-strong">
        Check Your Email
    </TypoTitle>
    <TypoBody className="text-neutral-text font-medium text-center">
      An email has been sent to the address you gave us, please confirm
      your email address to get your SATPrac journey started!
    </TypoBody>
  </div>
);

const SignUpForm = ({ onCompleted }: { onCompleted: (data: { register: ClientToken }) => void }) => {
  const [recaptcha, setRecaptcha] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: { email: '', password: '', passwordConfirmation: '', firstName: '', lastName: '', phone: '' },
    resolver: yupResolver(RegisterSchema),
  });

  const onVerifyCaptcha = useCallback((token?: string) => {
    setRecaptcha(token);
  }, []);

  const [signUp, { loading }] = useMutation(REGISTER_MUTATION, {
    onCompleted,
    onError: (apolloError) => console.log(apolloError),
  });

  const onSubmit = useCallback(async ({
    email,
    password,
    firstName,
    lastName,
    phone,
  }: RegistrationInput) => {
    const input = { email, password, firstName, lastName, phone, recaptcha };
    clearErrors();
    await signUp({ variables: { input } });
    setRefreshReCaptcha(r => !r);
    reset();
  }, [signUp, clearErrors, recaptcha, reset]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4">
        <TextField
          {...register('email')}
          type="email"
          placeholder="john.doe@gmail.com"
          label="Email"
          error={Boolean(errors?.email)}
          errorText={errors?.email?.message}
          required
        />
        <TextField
          {...register('password')}
          type="password"
          placeholder="my secret password"
          label="Password"
          error={Boolean(errors?.password)}
          errorText={errors?.password?.message}
          required
        />
        <TextField
          {...register('firstName')}
          type="text"
          placeholder="John"
          label="First Name (Optional)"
          error={Boolean(errors?.firstName)}
          errorText={errors?.firstName?.message}
        />
        <TextField
          {...register('passwordConfirmation')}
          type="password"
          placeholder="my secret password"
          label="Retype Password"
          error={Boolean(errors?.passwordConfirmation)}
          errorText={errors?.passwordConfirmation?.message}
          required
        />
        <TextField
          {...register('lastName')}
          type="text"
          placeholder="Doe"
          label="Last Name (Optional)"
          error={Boolean(errors?.lastName)}
          errorText={errors?.lastName?.message}
        />
        <TextField
          {...register('phone')}
          type="text"
          placeholder="Example: +12124567890"
          label="Phone (Optional)"
          error={Boolean(errors?.phone)}
          errorText={errors?.phone?.message}
        />
      </div>

      <div className="flex justify-between items-center min-h-[78px]">
        <GoogleReCaptcha
          onVerify={onVerifyCaptcha}
          refreshReCaptcha={refreshReCaptcha}
        />
        <Button type="submit" disabled={loading} className="ml-auto">
          {loading ? 'Signing Up' : 'Sign Up'}
        </Button>
      </div>
    </form>
  );
};

interface RegistrationInput {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

const RegisterSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(64).required(),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password')], 'Passwords must match'),
  firstName: yup.string().transform((_, v) => v || undefined),
  lastName: yup.string().transform((_, v) => v || undefined),
  phone: yup.string().test({
    name: 'valid-phone',
    message: 'Phone number is not valid',
    exclusive: true,
    test(value) {
      if (!value) return true;

      return isValidPhoneNumber(value);
    },
  }).transform((_, v) => v || undefined),
});

export default SignUp;
