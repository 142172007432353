import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TypoCallout, TypoTitle } from '@/components/Typography.tsx';
import Separator from '@/components/Separator.tsx';
import Button from '@/components/Button.tsx';
import TextField from '@/components/TextField';
import ActionButton from '@/components/ActionButton.tsx';
import useAuth from '@/hooks/useAuth.ts';
import LOGIN_MUTATION from '@/graphql/mutations/login.graphql';
import type { ClientToken } from '@/AuthProvider.tsx';

const SignIn = () => {
  const auth = useAuth();

  const onLoginCompleted = useCallback((data: { login: ClientToken }) => {
    const { login } = data;
    auth.onLogin(login);
  }, [auth]);

  return (
    <div className="w-full max-w-[23.75rem]">
      <div className="flex items-center justify-between">
        <TypoTitle className="text-neutral-text">Sign In</TypoTitle>
        <Link to="/sign-up" className="flex-shrink-0">
          <ActionButton>Sign Up</ActionButton>
        </Link>
      </div>
      <Separator className="mt-2.5 mb-4" />
      <SignInForm onCompleted={onLoginCompleted} />
    </div>
  );
};

const SignInForm = ({ onCompleted }: { onCompleted: (data: { login: ClientToken }) => void }) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(LoginSchema),
  });

  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted,
    onError: (apolloError) => console.log(apolloError),
  });

  const onSubmit = useCallback(async ({ email = '', password = '' }) => {
    const input = { email, password };
    clearErrors();
    await login({ variables: { input } });
    reset();
  }, [login, clearErrors, reset]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <TextField
        {...register('email')}
        type="email"
        placeholder="john.doe@gmail.com"
        label="Email"
        error={Boolean(errors?.email)}
        errorText={errors?.email?.message}
        required
      />
      <TextField
        {...register('password')}
        type="password"
        placeholder="my secret password"
        label="Password"
        error={Boolean(errors?.password)}
        errorText={errors?.password?.message}
        autoComplete="new-password"
        required
      />
      <div className="flex items-center justify-end gap-4">
        <Link to="/password-reset">
          <TypoCallout className="text-neutral-text-softer font-medium">Forgotten Password?</TypoCallout>
        </Link>
        <Button type="submit" disabled={loading}>
          {loading ? 'Signing In' : 'Sign In'}
        </Button>
      </div>
    </form>
  );
};

const LoginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(64).required(),
});

export default SignIn;
