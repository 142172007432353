import Blocks, { ConfigProp, DataProp, RenderFn } from 'editorjs-blocks-react-renderer';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';

export interface RendererProps {
  data: DataProp;
  config?: ConfigProp;
}

const BlocksRenderer = ({ data, config }: RendererProps) => (
  <Blocks
    data={data}
    config={{
      // TODO: Update style config once design is ready
      delimiter: {
        className: 'border border-2'
      },
      embed: {
        className: 'border-0 select-none'
      },
      header: {
        className: 'font-bold'
      },
      image: {
        className: 'w-full my-2 max-w-screen-md select-none',
        actionsClassNames: {
          stretched: 'w-full h-80 object-cover',
          withBorder: 'border border-2',
          withBackground: 'p-2',
        }
      },
      list: {
        className: 'list-inside'
      },
      paragraph: {
        className: 'text-body my-2',
        actionsClassNames: {
          alignment: 'text-{alignment}',
        }
      },
      quote: {
        className: 'py-3 px-5 italic'
      },
      table: {
        className: 'table-auto'
      },
      math: {
        className: 'overflow-x-auto px-0.5 bg-inherit'
      },
      ...config
    }}
    renderers={{ math: MathRenderer }}
  />
);

const MathRenderer: RenderFn<{ math: string; }> = ({ data, className = '' }) => (
  <div className={className}>
    <BlockMath math={data.math} />
  </div>
);

export default BlocksRenderer;
