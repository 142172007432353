import { useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import TestScreen from './TestScreen.tsx';
import Loader from '@/components/Loader.tsx';
import useExamComponent from '@/hooks/exam/useExamComponent.ts';

const TestReviewPage = () => {
  const { state } = useLocation();
  const { examId, clientExamId, examComponentId, question } = useParams();

  return (
    <TestReview
      examId={Number(examId)}
      examComponentId={Number(examComponentId)}
      clientExamId={Number(clientExamId)}
      question={Number(question)}
      fromPath={state?.from ?? '/dashboard'}
    />
  );
};

interface TestReviewProps {
  examId: number;
  clientExamId: number;
  examComponentId: number;
  question: number;
  fromPath: string;
}

const TestReview = ({ examId, examComponentId, clientExamId, fromPath, ...props }: TestReviewProps) => {
  const navigate = useNavigate();
  const { loading, ...examComponentProps } = useExamComponent(examComponentId, clientExamId);

  const onComplete = useCallback(async () => {
    navigate(fromPath);
  }, [fromPath, navigate]);

  const onQuestionChange = useCallback((questionNumber: number) => {
    navigate(`/review/${examId}/${clientExamId}/${examComponentId}/${questionNumber}`, { state: { from: fromPath } });
  }, [examComponentId, clientExamId, examId, fromPath, navigate]);

  if (loading) return <Loader />;

  return (
    <TestScreen
      onCompleteExam={onComplete}
      onQuestionChange={onQuestionChange}
      isPreview
      {...examComponentProps}
      {...props}
    />
  );
};

export default TestReviewPage;
