import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { toJSON } from '@/utils/helpers.tsx';
import CLIENT_EXAM_QUERY from '@/graphql/queries/client-exam.graphql';
import EXAM_COMPONENTS_QUERY from '@/graphql/queries/exams-components.graphql';

const useExamData = (examId: number, clientExamId: number) => {
  const { data: clientData, loading } = useQuery(CLIENT_EXAM_QUERY, {
    variables: { clientExamId },
  });

  const { data: examData, loading: componentsLoading } = useQuery(EXAM_COMPONENTS_QUERY, {
    variables: { examId },
  });

  const examName = useMemo(() => clientData?.clientExam?.exam?.name, [clientData?.clientExam?.exam?.name]);
  const examDescription = useMemo(() =>
    toJSON(clientData?.clientExam?.exam?.description ?? ''), [clientData?.clientExam?.exam?.description]);

  const examType = useMemo(() =>
    clientData?.clientExam?.exam?.examType?.name, [clientData?.clientExam?.exam?.examType?.name]);

  const components = useMemo(() => {
    const components = clientData?.clientExam?.components ?? [];

    return components.map((c: any) => {
      const currentExamComponent = examData?.examsComponents?.find((ec: any) => ec.componentId === c.examComponentId);
      const name = currentExamComponent?.name ?? '';
      const description = toJSON(currentExamComponent?.description ?? '');

      return {
        ...c,
        name,
        description,
        componentType: currentExamComponent?.componentType,
      };
    });

  }, [clientData?.clientExam?.components, examData?.examsComponents]);

  return {
    examName,
    examDescription,
    examType,
    components,
    loading: loading || componentsLoading,
  };
};

export default useExamData;
