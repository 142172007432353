import { Navigate } from 'react-router-dom';
import useAuth from '@/hooks/useAuth.ts';
import Header from './Header.tsx';
import Hero from './Hero.tsx';
import Pricing from './Pricing.tsx';
import Footer from './Footer.tsx';

const Home = () => {
  const auth = useAuth();

  if (auth.clientId) return <Navigate to="/dashboard" replace />;

  return (
    <main className="h-screen landing-main">
      <Header />
      <Hero />
      <Pricing />
      <Footer />
    </main>
  );
};

export default Home;
