import { useState } from 'react';
import NavContainer from './NavContainer';
import Button from '../Button';
import UserInfo from '@/components/UserInfo.tsx';
import QuestionsPopover from '../QuestionsPopover';
import { IQuestion } from '@/types/question.types.ts';

interface IBottomBarProps {
  isPreview: boolean;
  title?: string;
  questions: IQuestion[];
  currentQuestion: number;
  onQuestionSelect: (questionNumber: number) => void;
  onCompleteExam: () => void;
  onPrevQuestion: () => void;
  onNextQuestion: () => void;
  className?: string;
}

const BottomBar = (props: IBottomBarProps) => {
  const {
    currentQuestion,
    onQuestionSelect,
    onPrevQuestion,
    onNextQuestion,
    onCompleteExam,
    questions,
    isPreview,
    title = 'Current Module Questions',
    className = ''
  } = props;

  const isLastQuestion = currentQuestion >= questions.length;
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  return (
    <NavContainer className={className}>
      <UserInfo className="flex-1" />
      <QuestionsPopover
        isPreview={isPreview}
        isOpen={isPopoverOpen}
        setOpen={setPopoverOpen}
        list={questions}
        selected={currentQuestion}
        onQuestionSelect={onQuestionSelect}
        onCompleteExam={onCompleteExam}
        title={title}
        isLastQuestion={isLastQuestion}
      />
      <div className="flex-1 flex justify-end gap-3">
        <Button disabled={currentQuestion < 2} color="secondary" onClick={onPrevQuestion}>Back</Button>
        {
          isLastQuestion
            ? isPreview
              ? <Button onClick={onCompleteExam}>Close</Button>
              : <Button onClick={() => setPopoverOpen(true)} disabled={isPopoverOpen}>Review</Button>
            : <Button onClick={onNextQuestion}>Next</Button>
        }
      </div>
    </NavContainer>
  );
};

export default BottomBar;
