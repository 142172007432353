import { useEffect, useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { ResetPasswordIllustration } from '@/components/Icons.tsx';
import { TypoBody, TypoTitle } from '@/components/Typography.tsx';
import Loader from '@/components/Loader.tsx';
import Button from '@/components/Button.tsx';
import useAuth from '@/hooks/useAuth.ts';
import VERIFY_PASS_RESET_MUTATION from '@/graphql/mutations/verify-password-reset.graphql';
import type { ClientToken } from '@/AuthProvider.tsx';

const VerifyPasswordReset = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('code');

  if (!email || !code) return <Navigate to="/password-reset" replace />;

  return <VerifyPassResetInner email={email} code={code} />;
};

const VerifyPassResetInner = (props: { email: string; code: string }) => {
  const [initialLoading, setLoading] = useState(true);
  const { email, code: passwordResetCode } = props;
  const auth = useAuth();

  const [verifyReset, { loading: resetLoading, error }] = useMutation(
    VERIFY_PASS_RESET_MUTATION, {
      variables: {
        input: { email, passwordResetCode }
      },
      onCompleted: (data: { verifyPasswordReset: ClientToken }) => {
        const { verifyPasswordReset } = data;
        setLoading(false);
        auth.onLogin(verifyPasswordReset);
      },
      onError: () => setLoading(false)
    }
  );

  const loading = initialLoading || resetLoading;

  useEffect(() => {
    const abortController = new AbortController();

    const callback = async () => verifyReset({
      context: {
        fetchOptions: { signal: abortController.signal }
      }
    });

    callback();

    return () => abortController.abort();
  }, [verifyReset]);

  if (loading) return <Loader />;

  return (
    <div className="flex flex-col items-center justify-center gap-4 max-w-lg">
      <ResetPasswordIllustration />
      <ErrorContent error={error} />
    </div>
  );
};

const ErrorContent = ({ error }: { error?: ApolloError }) => {
  const errors = error?.graphQLErrors[0]?.extensions?.fields ?? {};
  const errorText = Object.values(errors).join(', ');

  return (
    <>
      <TypoTitle className="text-danger">
        Something went wrong!
      </TypoTitle>
      <TypoBody className="text-neutral-text font-medium text-center">
        {errorText}
      </TypoBody>
      <Link to="/password-reset">
        <Button>Try Again</Button>
      </Link>
    </>
  );
};

export default VerifyPasswordReset;
